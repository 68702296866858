const admin = {};

const client = {
  root: "/",
  dashboard: "/client/jobevaluation",
  auth: {
    login: "/client/login",
    forgot_password: "/client/forgot-password",
    reset_password: "/client/reset-password",
  },
  sub_admins: {
    listing: "/client/sub-admins",
    create: "/client/sub-admins/create",
    edit: (sub_admin_id = ":sub_admin_id") =>
      `/client/sub-admins/edit/${sub_admin_id}`,
  },
  jobEvaluation: {
    listing: "/client/jobevaluation",
    details: (evaluationId = ":evaluationId") =>
      `/client/job/evaluation/${evaluationId}/details`,
    create: (evaluationId = ":evaluationId") =>
      `/client/job/evaluation/${evaluationId}/details/create`,
    summary: (evaluationId = ":evaluationId") =>
      `/client/job/evaluation/${evaluationId}/details/summary`,
  },
  jobGradeDefination: {
    listing: "/client/gradedefination",
  },
  jobLibrary: {
    listing: "/client/job-library",
  },
  payNet: {
    listing: "/client/pay-net",
  },
  payNetJobFunctions: {
    listing: "/client/custom-job-library",
  },
  company: {
    edit: (company_id = ":company_id") => `/company/${company_id}/edit`,

    profile: {
      details: (company_id = ":company_id") => `/company/${company_id}`,
      edit: (company_id = ":company_id") => `/company/${company_id}/edit`,
      businessUnits: {
        listing: (company_id = ":company_id") =>
          `/company/${company_id}/business-units`,
        regions: (
          company_id = ":company_id",
          business_unit_id = ":business_unit_id"
        ) => `/company/${company_id}/business-units/${business_unit_id}`,
        countries: (
          company_id = ":company_id",
          business_unit_id = ":business_unit_id",
          region_id = ":region_id"
        ) =>
          `/company/${company_id}/business-units/${business_unit_id}/${region_id}`,
        legalEntries: (
          company_id = ":company_id",
          business_unit_id = ":business_unit_id",
          region_id = ":region_id",
          country_id = ":country_id"
        ) =>
          `/company/${company_id}/business-units/${business_unit_id}/${region_id}/${country_id}`,
      },
      regions: (company_id = ":company_id") => `/company/${company_id}/regions`,
    },
  },
};

export { admin, client };
