import { useState, useCallback } from "react";
import "./PayNet.less";
import SearchBox from "@/components/searchInput";
import jobFunctions from "./jobFunctions.json";
import JobFunctionBtn from "./JobFunctionBtn";
import { GrDownload } from "react-icons/gr";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";

interface JobFunction {
  name: string;
  subFunction?: SubJobFunction[];
}

interface SubJobFunction {
  name: string;
  docs: Record<string, string[]>;
}

const PayNet = () => {
  const [searchInput, setSearchInput] = useState("");
  const [jobFunctionsState, setJobFunctionsState] = useState(() =>
    sortJobFunctions(jobFunctions)
  );
  const [selectedJobFunc, setSelectedJobFunc] = useState<JobFunction | null>(
    null
  );
  const [selectedSubJobFunc, setSelectedSubJobFunc] =
    useState<SubJobFunction | null>(null);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);

  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const level = event.target.value;
    setSelectedLevels(prevLevels =>
      prevLevels.includes(level)
        ? prevLevels.filter(l => l !== level)
        : [...prevLevels, level]
    );
  };

  const handleSelectJobFunc = (jobFunc: JobFunction) => {
    setSelectedJobFunc(jobFunc);
    setSelectedSubJobFunc(null);
  };

  const handleSelectSubJobFunc = (subJobFunc: SubJobFunction) => {
    setSelectedSubJobFunc(subJobFunc);
  };

  const handleBack = () => {
    setSelectedJobFunc(null);
    setSearchInput("");
    setJobFunctionsState(jobFunctions);
  };

  // const filterJobFunctions = useCallback((value: string) => {
  //   setSearchInput(value);

  //   if (selectedJobFunc?.subFunction) {
  //     const filteredSubFunctions = value
  //       ? selectedJobFunc.subFunction.filter(sjf =>
  //         sjf.name.toLowerCase().includes(value.toLowerCase())
  //       )
  //       : selectedJobFunc.subFunction;

  //     setSelectedJobFunc(prev => prev ? { ...prev, subFunction: filteredSubFunctions } : null);
  //   }

  //   const filteredJobFunctions = value
  //     ? jobFunctions.filter(jf => jf.name.toLowerCase().includes(value.toLowerCase()))
  //     : jobFunctions;

  //   setJobFunctionsState(filteredJobFunctions);
  // }, [selectedJobFunc]);

  const filterJobFunctions = useCallback(
    (value: string) => {
      setSearchInput(value);

      if (selectedJobFunc?.subFunction) {
        if (value) {
          const filteredSubFunctions = selectedJobFunc.subFunction.filter(sjf =>
            sjf.name.toLowerCase().includes(value.toLowerCase())
          );
          setSelectedJobFunc(prev =>
            prev ? { ...prev, subFunction: filteredSubFunctions } : null
          );
        } else {
          const originalJobFunction = jobFunctions.find(
            jf => jf.name === selectedJobFunc.name
          );
          setSelectedJobFunc(originalJobFunction || null);
        }
      }

      const filteredJobFunctions = value
        ? jobFunctions.filter(jf => {
            // Convert value to lowercase for case-insensitive comparison
            const searchValue = value.toLowerCase();

            // Check if the job function name contains the search term
            const jobFunctionMatches = jf.name
              .toLowerCase()
              .includes(searchValue);

            // Check if any subFunction name contains the search term
            const subFunctionMatches = jf.subFunction.some(sf =>
              sf.name.toLowerCase().includes(searchValue)
            );

            // Return the jobFunction if it matches either the job function name or any subFunction name
            return jobFunctionMatches || subFunctionMatches;
          })
        : jobFunctions;

      setJobFunctionsState(filteredJobFunctions);

      setJobFunctionsState(filteredJobFunctions);
    },
    [
      jobFunctions,
      selectedJobFunc,
      setSearchInput,
      setSelectedJobFunc,
      setJobFunctionsState,
    ]
  );

  const renderSubJobFunctions = () =>
    selectedJobFunc?.subFunction
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((subJobFunction, index) => (
        <JobFunctionBtn
          key={index}
          onClick={() => handleSelectSubJobFunc(subJobFunction)}
          jobFunction={subJobFunction}
          selected={selectedSubJobFunc}
        />
      ));

  const renderLevels = () =>
    Object.keys(selectedSubJobFunc?.docs || {}).map(level => (
      <label
        key={level}
        className="selectLevelCheckBox flex items-center gap-2 cursor-pointer"
      >
        <input
          type="checkbox"
          value={level}
          checked={selectedLevels.includes(level)}
          onChange={handleLevelChange}
          className="w-5 h-5 text-[#00519d] accent-[#00519d] cursor-pointer"
        />
        <span className="block w-fit text-xl font-[500]">{level}</span>
      </label>
    ));

  const renderDocuments = () => {
    return selectedLevels
      .flatMap(
        level =>
          selectedSubJobFunc?.docs[level].map((doc: string, index) => ({
            doc,
            level,
          })) || []
      )
      .map(({ doc, level }, index) => {
        let folderName = selectedJobFunc
          ? encodeURIComponent(selectedJobFunc?.name)
          : "";
        let docName = selectedSubJobFunc
          ? encodeURIComponent(selectedSubJobFunc?.name)
          : "";

          console.log("kk",selectedSubJobFunc?.name)
        let encodeLevel = encodeURIComponent(level);

        return (
          <div
            key={index}
            className="docLevel flex items-center gap-2 text-themeBlue font-semibold mt-2 text-base"
          >
            <GrDownload className="text-lg font-bold" />
            <a
              href={`/Paynet_JDs/${folderName}/${selectedSubJobFunc?.name}/${doc}`}
              // target="_blank"
              rel="noopener noreferrer"
              download
            >
              {level + " > " + doc}
            </a>
            <FaCircleCheck className="text-green-400" />
          </div>
        );
      });
  };

  return (
    <div className="bg-themeCore min-h-screen h-full">
      <div className="jobCreationListing bg-white py-6 rounded-lg">
        <div className="jobCreationBar bg-white px-7 flex justify-between items-center my-7 sticky top-0 z-10">
          <div className="title text-themeBlue font-[600] text-3xl flex items-center gap-2">
            {selectedJobFunc && (
              <IoIosArrowBack className="cursor-pointer" onClick={handleBack} />
            )}
            <div>
              <h1 className="text-themeBlue font-[600]">TAREF PAY NET</h1>
              <div className="appTitle text-2xl font-[500] ">
                {selectedJobFunc
                  ? "Select your preferred Job Sub-Function:"
                  : "Select your preferred Job Function:"}
              </div>
            </div>
          </div>
          <SearchBox
            placeholder="Type to search..."
            value={searchInput}
            onChange={filterJobFunctions}
          />
        </div>

        <div className="px-7">
          {selectedJobFunc ? (
            <>
              <div className="PayNetList grid grid-cols-4 gap-5 flex-wrap mt-11">
                {renderSubJobFunctions()}
              </div>

              {selectedSubJobFunc &&
                selectedJobFunc.subFunction &&
                selectedJobFunc.subFunction.length > 0 && (
                  <>
                    <div className="appTitle text-2xl font-[500] mt-16">
                      Documents for {selectedSubJobFunc.name}:
                    </div>
                    <div className="mt-8 flex items-center gap-5">
                      {renderLevels()}
                    </div>

                    {selectedLevels.length > 0 && (
                      <>
                        <div className="appTitle text-2xl font-[500] mt-16">
                          Downloadable Sub-Functions Files:
                        </div>
                        <div className="PayNetList mt-11">
                          {renderDocuments()}
                        </div>
                      </>
                    )}
                  </>
                )}
            </>
          ) : (
            <>
              <div className="PayNetList grid grid-cols-4 gap-5 flex-wrap mt-11">
                {jobFunctionsState.map((jobFunction, index) => (
                  <JobFunctionBtn
                    key={index}
                    onClick={() => handleSelectJobFunc(jobFunction)}
                    jobFunction={jobFunction}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayNet;

// Helper function to sort job functions
const sortJobFunctions = (jobFunctions: JobFunction[]) =>
  [...jobFunctions].sort((a, b) => a.name.localeCompare(b.name));
