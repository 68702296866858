

import { useCreatePayNetJobFunctionMutation, useCreatePayNetJobSubFunctionMutation } from "@/services";
import React, { FormEvent, useEffect, useState } from "react";

import { showSuccessPopup } from "@/utils";
import { useSelector } from "react-redux";
import { getSelectedCompanyFromStorage } from "@/services/cookies";
import { useTypedSelector } from "@/hooks";


export interface JobFunction {
    id: number;
    name: string;
    custom_job_sub_functions: SubJobFunction[];
}

interface FileUrl {
    [level: string]: string[];
}

interface SubJobFunction {
    id: number;
    name: string;
    file_url: FileUrl;
}

type CustomPayNetModalProps = {
    title?: string;
    onClose?: () => void;
    selectedJobFunc: JobFunction | null;
};
const CustomPayNetModal: React.FC<CustomPayNetModalProps> = ({ title = "Add a Function", onClose = () => { }, selectedJobFunc }) => {
    const [createPayNetJobFunction, { isLoading: isLoadingFunction, isError: isErrorFunction, isSuccess: isSuccessFunction }] =
        useCreatePayNetJobFunctionMutation();
    const [createPayNetJobSubFunction, { isLoading: isLoadingSubFunction, isError: isErrorSubFunction, isSuccess: isSuccessSubFunction }] =
        useCreatePayNetJobSubFunctionMutation();

    // const JE_DATA = useSelector((state) => state)
    // console.log("JE_DATA", JE_DATA)

    const { user } = useTypedSelector(state => state.auth);
    const { id: company_id } = user?.company || {};
    console.log("company_id", company_id)

    // Conditionally select mutation based on `selectedJobFunc`
    const mutationFunction = selectedJobFunc ? createPayNetJobSubFunction : createPayNetJobFunction;
    const isLoading = selectedJobFunc ? isLoadingSubFunction : isLoadingFunction;
    let isError = selectedJobFunc ? isErrorSubFunction : isErrorFunction;
    let isSuccess = selectedJobFunc ? isSuccessSubFunction : isSuccessFunction;

    // State for form fields
    const [jobFunction, setJobFunction] = useState("");
    const [description, setDescription] = useState("");

    // State for form validation errors
    const [errors, setErrors] = useState<{ jobFunction?: string; description?: string }>({});

    // State for showing feedback messages
    const [feedbackMessage, setFeedbackMessage] = useState("");

    // Validation function
    const validateField = (field: string, value: string) => {
        let error = "";
        if (!value.trim()) {
            error = `${field} is required.`;
        }
        return error;
    };

    const handleJobFunctionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setJobFunction(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            jobFunction: validateField("Job function", value),
        }));
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setDescription(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            description: validateField("Description", value),
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setFeedbackMessage("");

        const validationErrors = {
            jobFunction: validateField("Job function", jobFunction),
            description: validateField("Description", description),
        };

        if (validationErrors.jobFunction || validationErrors.description) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});



        try {
            if (selectedJobFunc) {
                const payload = {
                    custom_job_function_id: selectedJobFunc?.id,
                    name: jobFunction,
                    description: description,

                };
                await createPayNetJobSubFunction(payload).unwrap();
                setFeedbackMessage(`${title} function added successfully!`);

                setJobFunction("");
                setDescription("");
            } else {
                const payload = {
                    name: jobFunction,
                    description: description,
                    is_active: "TRUE",
                };


                if (company_id) {

                    await createPayNetJobFunction({ payload: payload, company_id: company_id }).unwrap();
                }
                setFeedbackMessage("Job function added successfully!");
                setJobFunction("");
                setDescription("");
            }
            showSuccessPopup({
                title: `New ${title} Created`,
                desc: `You have successfully created new ${title}.`,
            });

            onClose();

        } catch (error) {
            if (error) {
                setFeedbackMessage(error?.message);
            } else {
                // Handle other types of errors (e.g., strings or objects)
                
                setFeedbackMessage("An unexpected error occurred.");
            }
        }
    };





    return (
        <div
            id="default-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex justify-center items-center overflow-y-auto transition-all"
        >
            {/* Dark blurred background */}
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-all"></div>

            {/* Modal container */}
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow ">
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Add a {title} Function
                        </h3>
                        <button
                            disabled={isLoading}
                            onClick={onClose}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  "
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={handleSubmit} className="p-4 md:p-5 space-y-6">
                        {/* Feedback messages */}
                        {feedbackMessage && (
                            <p
                                className={`text-sm font-medium ${isSuccess ? "text-green-500" : "text-red-500"
                                    }`}
                            >
                                {feedbackMessage}
                            </p>
                        )}

                        <div>
                            <label
                                htmlFor="job-function"
                                className="block text-sm font-medium text-gray-700  mb-2"
                            >
                                {title} Function
                            </label>
                            <input
                                type="text"
                                id="job-function"
                                value={jobFunction}
                                onChange={handleJobFunctionChange}
                                className={`block w-full px-4 py-2 border rounded-lg text-sm text-gray-900 bg-gray-50 border-gray-300 focus:bg-white outline-none ${errors.jobFunction ? "border-red-500" : ""
                                    }`}
                                placeholder="Enter the job function"
                            />
                            {errors.jobFunction && (
                                <p className="text-sm text-red-500 mt-1">{errors.jobFunction}</p>
                            )}
                        </div>

                        <div>
                            <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-700  mb-2"
                            >
                                Description
                            </label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={handleDescriptionChange}
                                className={`block focus:bg-white outline-none w-full px-4 py-2 border rounded-lg text-sm text-gray-900 bg-gray-50 border-gray-300 ${errors.description ? "border-red-500" : ""
                                    }`}
                                placeholder="Provide a brief description"
                            ></textarea>
                            {errors.description && (
                                <p className="text-sm text-red-500 mt-1">{errors.description}</p>
                            )}
                        </div>
                    </form>

                    {/* Modal footer */}
                    <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b ">
                        <button
                            onClick={handleSubmit}
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                            disabled={isLoading}
                        >
                            {isLoading ? "Adding..." : "Add"}
                        </button>
                        <button
                            onClick={onClose}
                            type="button"
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default CustomPayNetModal;

