import { UserRoles } from "@/constants";
import { IRoute } from "@/router";
import { checkAuthenticationBasedOnAppAccess, checkPermission } from "@/utils";
import { useTypedSelector } from "./useTypedSelector";

export default function useAppRoleGuard(route: IRoute) {
  const { user } = useTypedSelector(state => state.auth);

  let isAuthenticated = false;
  let isAuthorized = true;

  //Check Authentication
  if (user?.id) {
    isAuthenticated = true;
    // Check for Application and No permission Check
    if (user.role === UserRoles.COMPANY_SUPER_USER) {
      isAuthenticated = checkAuthenticationBasedOnAppAccess();
    }
    // Dont Check Anything ,
    if (user.role === UserRoles.TOM_MASTER_USER) {
    } else {
      // Check for Application && Check For Persissions
      isAuthenticated = checkAuthenticationBasedOnAppAccess();
      isAuthorized = checkPermission(route.permission);
    }
  }

  return {
    isAuthenticated,
    isAuthorized,
  };
}
