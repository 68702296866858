import React, { useEffect, useState } from "react";
import Stepper from "@components/stepper";
import CustomButton from "@components/customButton";

// import CreateJob from "./CreateJob";
import { Button, Col, Input, Row, Select } from "antd";
import Modal from "@components/Modal";
import {
  IJobFunctionItem,
  IModal,
  ISubJobFunctionItem,
  IBusinessUnitItem,
  ICountry,
  IRegion,
} from "@types";
import {
  ErrorServices,
  ICreateJobForEvaluation,
  useCreateJobForEvaluationMutation,
  useFetchAllJobFunctionsQuery,
  useFetchAllBusinessUnitsQuery,
} from "@services";
import { useTypedSelector } from "@/hooks";
import { handleJobDetails, KeyAndDisplayName } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showSuccessPopup } from "@/utils";
import { paths } from "@/router";
import { evaluationModelOptions } from "@/constants";

import CustomFormSelect from "@components/customSelect";
import CustomFormInput from "@components/customInput";

const { Option } = Select;

export type dropdown = string | undefined;

export interface IAddJob extends IModal {
  isVisible: boolean;
  setIsVisible: () => void;
}
interface Step {
  title: string;
}

interface JobCreationModalProps {
  visible: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const JobCreationModal: React.FC<JobCreationModalProps> = ({
  visible,
  onClose,
  children,
}) => {
  let jobEvaluationForm: any = {};

  const steps: Step[] = [
    {
      title: "Evaluation Information",
    },
    {
      title: "Job details",
    },
  ];

  const [current, setCurrent] = useState<number>(0);

  const next = () => {
    if (current < steps.length) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  const history = useHistory();

  const dispatch = useDispatch();
  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;
  const { countries } = useTypedSelector(state => state.countries);

  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};

  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );

  const { jobDetails } = useTypedSelector(state => state?.jobEvaluation);

  const disabled =
    !jobDetails.departmentName ||
    !jobDetails.evaluation_model?.key ||
    !jobDetails.jobTitle ||
    !jobDetails.job_function?.id ||
    !jobDetails.noOfDirectReports ||
    !jobDetails.sub_job_function?.id;

  const [createJobForEvaluation, { isLoading }] =
    useCreateJobForEvaluationMutation();

  const onSubmit = async () => {
    try {
      const body: ICreateJobForEvaluation = {
        job_title: jobDetails.jobTitle,
        department_name: jobDetails.departmentName,
        business_unit: jobDetails.business_unit,
        region: jobDetails.region,
        country: jobDetails.country_id,
        evaluation_model: jobDetails.evaluation_model?.key,
        no_direct_report: jobDetails.noOfDirectReports,
        job_function: jobDetails.job_function?.id,
        sub_job_function: jobDetails.sub_job_function?.id,
      };
      const payload = await createJobForEvaluation({
        company_id,
        body,
      }).unwrap();

      payload.data.id &&
        history.push(paths.client.jobEvaluation.create(payload.data.id));
      showSuccessPopup({
        title: "New Job Created",
        desc: `You have successfully created new job.`,
      });
      onClose();
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const {
    departmentName,
    business_unit,
    region,
    country_id,
    evaluation_model,
    noOfDirectReports,

    jobTitle,
    job_function,
    sub_job_function,
  } = jobDetails;

  const modifiedJobDetails = {
    departmentName: jobDetails?.departmentName || "",
    business_unit: jobDetails?.business_unit || "",
    region: jobDetails?.region || "pak",
    country_id: jobDetails?.country_id || "03",
    evaluation_model: jobDetails?.evaluation_model || "",
    noOfDirectReports: jobDetails?.noOfDirectReports || "",
  };

  // Check if the button should be disabled based on the first six values
  const isButtonDisabled = Object.values(modifiedJobDetails)
    .slice(0, 6) // Consider only the first 6 values
    .some(value => !value);

  console.log("khi", isButtonDisabled);

  console.log("sagar", Object.values(jobDetails).slice(0, 6));

  const handleChange = (event: any) => {
    dispatch(
      handleJobDetails({
        [event.target.name]: event.target.value,
      })
    );
  };
  const mapEvaluationModelToOption = (
    evaluationModel: { key: any; display_name: string }[]
  ) => {
    return evaluationModel.map(item => ({
      id: item.key,
      name: item.display_name,
    }));
  };

  return (
    <>
      {visible ? (
        <>
          <div className="overflow-x-hidden transition-all delay-75  overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-16">
            <div className="relative my-20 mx-6 py-10 w-full">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full    bg-themeCore outline-none focus:outline-none">
                <div className="relative py-6 px-24 flex-auto">
                  <div className="Stepper w-fit mx-auto">
                    <Stepper
                      steps={steps}
                      current={current}
                      setCurrent={setCurrent}
                    />
                  </div>
                  <div className="content mt-14 p-5 border">
                    {current === 0 ? (
                      <div className="form2">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Create a job for your Organization
                        </h3>
                        <div className="jobDetailsInput mt-16 flex gap-5 flex-wrap">
                          <CustomFormInput
                            title="Department Name"
                            placeholder="Enter department name here"
                            name={"departmentName"}
                            onChange={handleChange}
                            value={departmentName || ""}
                          />
                          <CustomFormSelect
                            disabled={false}
                            title="Business Unit"
                            onChange={option => {
                              handleChange({
                                target: {
                                  name: "business_unit",
                                  value: option.id,
                                },
                              });
                            }}
                            value={business_unit || undefined}
                            placeholder="Select business unit from here"
                            options={businessUnits}
                          />

                          <CustomFormSelect
                            disabled={!business_unit}
                            title="Region"
                            onChange={option => {
                              handleChange({
                                target: { name: "region", value: option.id },
                              });
                            }}
                            value={region || undefined}
                            placeholder="Select Region from here"
                            options={
                              businessUnits?.filter(
                                (elm: any) => elm.id === business_unit
                              )[0]?.regions || []
                            }
                          />
                          <CustomFormSelect
                            disabled={!region}
                            title="Country"
                            onChange={option => {
                              handleChange({
                                target: {
                                  name: "country_id",
                                  value: option.id,
                                },
                              });
                            }}
                            value={country_id || undefined}
                            placeholder="Select country from here"
                            options={
                              businessUnits
                                ?.filter(
                                  (elm: any) => elm.id === business_unit
                                )[0]
                                ?.regions?.filter(
                                  (elm: any) => elm.id === region
                                )[0]?.countries || []
                            }
                          />

                          <CustomFormSelect
                            disabled={false}
                            title="Evaluation Model"
                            onChange={option => {
                              console.log(option);
                              handleChange({
                                target: {
                                  name: "evaluation_model",
                                  value: {
                                    key: option?.id,
                                    display_name: option?.name,
                                  },
                                },
                              });
                            }}
                            value={evaluation_model?.key || ""}
                            placeholder="Select evaluation model from here"
                            options={mapEvaluationModelToOption(
                              evaluationModelOptions
                            )}
                          />
                          <CustomFormInput
                            title="Enter No. Of Direct Report"
                            placeholder="Enter no of direct report here"
                            name={"noOfDirectReports"}
                            onChange={handleChange}
                            value={noOfDirectReports || ""}
                          />
                        </div>
                      </div>
                    ) : (
                      current === 1 && (
                        <div className="form2">
                          <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                            Create a job for you Organization
                          </h3>

                          <div className="jobDetailsInput mt-16 flex gap-5 flex-wrap">
                            <CustomFormInput
                              title="Job Title"
                              placeholder="Enter Job Title here"
                              name={"jobTitle"}
                              onChange={handleChange}
                              value={jobTitle || ""}
                            />

                            <CustomFormSelect
                              disabled={false}
                              title="Job Function"
                              onChange={option => {
                                handleChange({
                                  target: {
                                    name: "job_function",
                                    value: {
                                      id: option?.id,
                                      name: option?.name,
                                    },
                                  },
                                });
                                dispatch(
                                  handleJobDetails({
                                    sub_job_function: null,
                                  })
                                );
                                setSubJobFunctions(
                                  option?.job_sub_functions || []
                                );
                              }}
                              value={job_function?.id || undefined}
                              placeholder="Select job function from here"
                              options={jobFunctions}
                            />

                            <CustomFormSelect
                              disabled={!job_function?.id}
                              title="Job Sub-Function"
                              onChange={option => {
                                handleChange({
                                  target: {
                                    name: "sub_job_function",
                                    value: {
                                      id: option?.id,
                                      name: option?.name,
                                    },
                                  },
                                });
                              }}
                              value={sub_job_function?.id || undefined}
                              placeholder="Select job sub-function from here"
                              options={subJobFunctions
                                ?.slice()
                                ?.sort(function (a: any, b: any) {
                                  if (a.name < b.name) {
                                    return -1;
                                  }
                                  if (a.name > b.name) {
                                    return 1;
                                  }
                                  return 0;
                                })}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {children}
                </div>
                <div className="flex items-center gap-5 justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <CustomButton
                    disabledProp={current <= 0}
                    btnName="Go Back"
                    onClick={prev}
                    customClasses="!px-10"
                  />
                  <CustomButton
                    customClasses="!px-10"
                    disabledProp={current === 0 ? isButtonDisabled : disabled}
                    btnName={current > 0 ? "Submit" : "Continue"}
                    onClick={current === 0 ? next : onSubmit}
                  />
                  <CustomButton
                    customClasses="!px-14"
                    btnName="Close"
                    onClick={onClose}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default JobCreationModal;
