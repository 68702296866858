import { useUploadPayNetJobSubFunctionDocMutation } from "@/services";
import { showSuccessPopup } from "@/utils";
import React, { useRef, useState } from "react";

interface FileUrl {
  [level: string]: string[];
}

interface SubJobFunction {
  id: number;
  name: string;
  file_url: FileUrl;
}
type FileUploadWithLevelProps = {

  selectedSubJobFunc: SubJobFunction | null;
};
const FileUploadWithLevel: React.FC<FileUploadWithLevelProps> = ({ selectedSubJobFunc }) => {

  // console.log("selectedSubJobFunc", selectedSubJobFunc)
  const [
    uploadPayNetJobSubFunctionDoc,
    { isLoading: isLoadingUploadFunction, isError: isErrorFunction, isSuccess: isSuccessFunction },
  ] = useUploadPayNetJobSubFunctionDocMutation();

  const [selectedLevel, setSelectedLevel] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [levels, setLevels] = useState<string[]>(["Entry Level", "Mid-Career", "Manager", "Department Head"])
  const [newLevel, setNewLevel] = useState<string>("")

  // Replace with actual custom_job_sub_function_id value
  const customJobSubFunctionId = selectedSubJobFunc?.id.toString();

  // const levels = ["Entry Level", "Mid-Career", "Manager", "Department Head"];

  const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLevel(event.target.value || null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file && selectedLevel && selectedSubJobFunc?.id) {
      const formData = new FormData();
      formData.append("file", file); // No need to create a Blob; FormData handles it.
      formData.append("custom_job_sub_function_id", selectedSubJobFunc?.id.toString());
      formData.append("career_level", selectedLevel);


      // console.log(formData.get("file"));
      // console.log(formData.get("custom_job_sub_function_id"));
      // console.log(formData.get("career_level"));
      try {
        const response = await uploadPayNetJobSubFunctionDoc(formData).unwrap();
        console.log("Upload successful:", response);
        showSuccessPopup({
          title: `File Upload Success`,
          desc: `File has been successfully uploaded!`,
        });
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = ""; // Reset the file input
        }
        setSelectedLevel(null)
        setFile(null)
        // alert("File uploaded successfully!");
      } catch (error) {
        console.error("Upload failed:", error);
        alert("Failed to upload file. Please try again.");
      }
    }
  };




  // const handleUpload = async () => {
  //   if (file && selectedLevel  && selectedSubJobFunc?.id) {
  //     const formData = new FormData();
  //     const fileBlob = new Blob([file], { type: file.type });
  //     formData.append("file", fileBlob);
  //     formData.append("custom_job_sub_function_id", selectedSubJobFunc?.id.toString());
  //     formData.append("career_level", selectedLevel);

  //     console.log(formData.get("file"));
  //     console.log(formData.get("custom_job_sub_function_id"));
  //     console.log(formData.get("career_level"));
  //     try {
  //       const response = await uploadPayNetJobSubFunctionDoc(formData).unwrap();
  //       console.log("Upload successful:", response);
  //       alert("File uploaded successfully!");
  //     } catch (error) {
  //       console.error("Upload failed:", error);
  //       alert("Failed to upload file. Please try again.");
  //     }
  //   }
  // };


  const handleAddLevel = () => {
    if (newLevel && !levels.includes(newLevel)) {
      setLevels([...levels, newLevel])
      setSelectedLevel(newLevel) // Automatically select the new level
      setNewLevel("")
    }
  }

  return (
    <div className="max-w-md p-4 border rounded-lg shadow-md bg-white">
      <h2 className="text-lg font-bold mb-4">File Upload with Level</h2>

      <div className="mb-4">
        <label htmlFor="level-select" className="block mb-2 font-medium">
          Select Level
        </label>
        <select
          id="level-select"
          value={selectedLevel || ""}
          onChange={handleLevelChange}
          className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
        >
          <option value="" disabled>
            Select a level
          </option>
          {levels.map((level, index) => (
            <option key={index} value={level}>
              {level}
            </option>
          ))}
        </select>
      </div>


      <div className="mb-4">
        <label htmlFor="new-level" className="block mb-2 font-medium">
          Add Custom Level
        </label>
        <div className="flex items-center">
          <input
            type="text"
            id="new-level"
            value={newLevel}
            onChange={(e) => setNewLevel(e.target.value)}
            placeholder="Enter new level"
            className="flex-grow mr-2 px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
          />
          <button
            onClick={handleAddLevel}
            disabled={!newLevel}
            className={`px-4 py-2 text-white rounded-lg ${
              newLevel ? "bg-blue-500 hover:bg-blue-600" : "bg-gray-400 cursor-not-allowed"
            }`}
          >
            Add
          </button>
        </div>
      </div>

      <div className="mb-2">
        <label htmlFor="file-upload" className="block mb-2 font-medium">
          Upload File
        </label>
        <input
          type="file"
          accept=".doc,.docx"
          id="file-upload"
          ref={fileInputRef}
          onChange={handleFileChange}
          disabled={!selectedLevel}
          className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none ${!selectedLevel ? "opacity-50 cursor-not-allowed" : ""
            }`}
        />
      </div>
      <p className="mb-4 text-xs font-[500] italic text-[#00519d]">Note: Only .docx files can be uploaded.</p>

      <button
        onClick={handleUpload}
        disabled={!file || !selectedLevel || isLoadingUploadFunction}
        className={`w-full px-4 py-2 text-white rounded-lg font-medium ${file && selectedLevel
            ? "bg-blue-500 hover:bg-blue-600"
            : "bg-gray-400 cursor-not-allowed"
          }`}
      >
        {isLoadingUploadFunction ? "Uploading..." : "Upload"}
      </button>

      {isErrorFunction && <p className="text-red-500 mt-2">Failed to upload. Please try again.</p>}
      {/* {isSuccessFunction && <p className="text-green-500 mt-2">File uploaded successfully!</p>} */}
    </div>
  );
};

export default FileUploadWithLevel;
