import React, { useState } from "react";
import { FaAddressCard } from "react-icons/fa";
import { Steps } from "antd";
import "./style.css";

interface Step {
  title: string;
  content?: string;
}

interface StepperProps {
  steps: Step[];
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const StepperIconWrapper: React.FC<{ customClass: string }> = ({
  children,
  customClass,
}) => {
  return (
    <div className={`wrapper border-2 rounded-full p-3 ${customClass}`}>
      {children}
    </div>
  );
};

const Stepper: React.FC<StepperProps> = ({ steps, current, setCurrent }) => {
  const items = steps.map((item, index) => ({
    ...item,
    icon: (
      <StepperIconWrapper
        customClass={`${
          index+1 === current ? "text-themeBlue border-themeBlue" : ""
        }`}
        key={index}
      >
        <FaAddressCard />
      </StepperIconWrapper>
    ),
  }));

  return (
    <Steps
      current={current}
      className=""
      labelPlacement="vertical"
      size="small"
    >
      {items.map((item, index) => (
        <Steps.Step key={index} title={item.title} icon={item.icon} />
      ))}
    </Steps>
  );
};

export default Stepper;
