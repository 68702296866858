import { Col, Row } from "antd";
import { matchPath, useParams } from "react-router-dom";
import { FC } from "react";

import "./companyProfile.less";
import { IRoute, paths, Routes } from "@router";
import Tabs from "@components/Tabs";
import { tabs } from "./config";

interface ICompanyProfile {
  routes: IRoute[];
}

const CompanyProfile: FC<ICompanyProfile> = ({ routes }) => {
  const { company_id } = useParams<{ company_id: string }>();

  const match = matchPath(window.location.pathname, {
    path: paths.client.company.profile.regions("company_id"),
  });
  return (
    <div className="companyProfile">
      {match ? null : (
        <>
          <Row>
            <Col span={24}>
              <div className="main-heading mb-16">Company details</div>
            </Col>
          </Row>
          <Tabs
            param=":company_id"
            route={true}
            paramValue={company_id}
            tabs={tabs}
          />
        </>
      )}

      <div className="companyProfile__content">
        <Routes routes={routes} />
      </div>
    </div>
  );
};

export default CompanyProfile;
