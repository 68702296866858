import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Upload,
  Card,
  message,
  Space,
  Radio,
} from "antd";
import { useHistory } from "react-router-dom";
import Stepper from "@components/stepper";
import CustomButton from "@components/customButton";
import ListComponent from "@components/ListComponent";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { InboxOutlined } from "@ant-design/icons";

import "./createJobEvaluation.less";
import { useTypedSelector } from "@hooks";
import { useDispatch } from "react-redux";
import {
  handleDecisionMakingImpact,
  handleFinancialResponsibility,
  handleJobDetails,
  handleKnowledgeSkillAndApplication,
  handleProblemSolvingSolutioning,
  handleStakeHolderManagement,
  KeyAndDisplayName,
} from "@/store/jobEvaluation";
import {
  ErrorServices,
  IUpdateJobEvaluation,
  useCreateJobEvaluationMutation,
  useFetchAllApplicationsQuery,
  useFetchAllComplexitiesQuery,
  useFetchAllImpactOfDecisionMakingQuery,
  useFetchAllKnowledgeAndSkillsQuery,
  useFetchAllNatureOfCommunicationQuery,
  useFetchAllNonFinancialResponsibilityQuery,
  useFetchAllProblemSolvingAndSolutioningQuery,
  useFetchAllRevenueResponsibilityQuery,
  useFetchAllRoleImpactOnOrganisationQuery,
  useUpdateJobEvaluationMutation,
  useUploadJobEvaluationFormsMutation,
  useFetchAllBusinessUnitsQuery,
  useFetchAllJobFunctionsQuery,
} from "@services";
import { showSuccessPopup } from "@/utils";
import { paths } from "@/router";
import { EvaluationModelKeys, evaluationModelOptions1 } from "@/constants";

import JobEvaluationDataProvider from "../JobEvaluationDataProvider/JobEvaluationDataProvider";
import { DownloadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib/upload/interface";
import CustomFormSelect from "@components/customSelect";
import CustomScopeFormSelect from "@components/customSelect/scopeSelect";
import CustomInput from "@components/customInput";
// import JobStatusModal from "@/components/JobStatusModal";

import smiley from "./smiley.png";

import factorsJSON from "./factors.json";

import impactData from "./data.json";

interface FactorDetail {
  name: string;
  desc: string;
}

interface Factors {
  [key: string]: {
    [key: string]: FactorDetail;
  };
}

const { Option } = Select;
const { Dragger } = Upload;

interface RoleComponentProps {
  jobKey: any;
  getKeyandUpdateData: (item: any) => void;
}

const RoleComponent: React.FC<RoleComponentProps> = ({
  jobKey,
  getKeyandUpdateData,
}) => {
  const [selectedRoleType, setSelectedRoleType] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const [selectedSubOption, setSelectedSubOption] = useState<number | null>(
    null
  );
  const [selectedMoreSubOption, setSelectedMoreSubOption] = useState<
    number | null
  >(null);

  const [final, setFinalValues] = useState<number | null>(null);

  const impactData = {
    "Leadership Role": {
      display_name: "Leadership Role",
      key: 0,
      options: [
        {
          display_name: "Function Head",
          key: 1,
          sub_options: [
            {
              display_name: "Single Business Unit",
              key: 1,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 1, id: 3 },
                { display_name: "Regional", key: 2, id: 5 },
                { display_name: "Global", key: 3, id: 7 },
              ],
            },
            {
              display_name: "Multiple Business Units",
              key: 2,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 4, id: 5 },
                { display_name: "Regional", key: 5, id: 4 },
                { display_name: "Global", key: 6, id: 6 },
              ],
            },
          ],
        },
        {
          display_name: "Business Head",
          key: 2,
          sub_options: [
            {
              display_name: "Single Business Unit",
              key: 11,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 1, id: 4 },
                { display_name: "Regional", key: 2, id: 6 },
                { display_name: "Global", key: 3, id: 8 },
              ],
            },
            {
              display_name: "Multiple Business Units",
              key: 12,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 4, id: 6 },
                { display_name: "Regional", key: 5, id: 8 },
                { display_name: "Global", key: 6, id: 8 },
              ],
            },
          ],
        },
      ],
    },
    "Non Leadership Role": {
      display_name: "Non Leadership Role",
      key: 1,
      options: [
        {
          display_name: "Team",
          key: 0,
          id: 0,
        },
        {
          display_name: "Department",
          key: 1,
          id: 1,
        },
        {
          display_name: "Corporate or Global",
          key: 2,
          id: 2,
        },
      ],
    },
  };

  let saveData = (jobKey: number, data: any) => {
    let storedData = JSON.parse(localStorage.getItem("jobData") || "{}");
    storedData[jobKey] = data;
    localStorage.setItem("jobData", JSON.stringify(storedData));
  };

  const handleRoleTypeSelection = (roleType: string) => {
    const dataToSave = {
      jobId: jobKey,
      selectedRole: roleType,
    };

    saveData(jobKey, dataToSave);
    setSelectedRoleType(roleType);
    setSelectedRole(null);
    setSelectedSubOption(null);
    setSelectedMoreSubOption(null);
  };

  const handleRoleSelection = (roleKey: number, role: any) => {
    const dataToSave = {
      jobId: jobKey,
      selectedRole: selectedRoleType,
      selectedOption: roleKey,
    };

    saveData(jobKey, dataToSave);
    setSelectedRole(roleKey);
    setSelectedSubOption(null);
    setSelectedMoreSubOption(null);
  };

  const handleRoleSelection1 = (roleKey: number, role: any) => {
    const dataToSave = {
      jobId: jobKey,
      selectedRole: selectedRoleType,
      selectedOption: roleKey,
    };

    saveData(jobKey, dataToSave);
    setSelectedRole(roleKey);
    setSelectedSubOption(null);
    setSelectedMoreSubOption(null);
    getKeyandUpdateData(role);
    setFinalValues(role?.id);
  };

  const handleSubOptionSelection = (subOptionKey: number, data: any) => {
    const dataToSave = {
      jobId: jobKey,
      selectedRole: selectedRoleType,
      selectedOption: selectedRole,
      selectedSubOption: subOptionKey,
    };

    saveData(jobKey, dataToSave);
    setSelectedSubOption(subOptionKey);
    setSelectedMoreSubOption(null); // Reset selected more sub-option when sub-option changes
  };

  const handleMoreSubOptionSelection = (
    moreSubOptionKey: number,
    subOption: any
  ) => {
    const dataToSave = {
      jobId: jobKey,
      selectedRole: selectedRoleType,
      selectedOption: selectedRole,
      moreSubOption: selectedSubOption,
      selectedSubOption: moreSubOptionKey,
      final: subOption?.id,
    };

    saveData(jobKey, dataToSave);

    setSelectedMoreSubOption(moreSubOptionKey);
    setFinalValues(subOption?.id);
    getKeyandUpdateData(subOption);
  };

  const fetchDataFromLocalStorage = (jobKey: number) => {
    const storedData = JSON.parse(localStorage.getItem("jobData") || "{}");
    return storedData[jobKey] || null;
  };

  useEffect(() => {
    const data = fetchDataFromLocalStorage(jobKey);
    if (data) {
      setSelectedRoleType(data?.selectedRole);
      setSelectedRole(data?.selectedOption);
      setSelectedSubOption(data?.moreSubOption);
      setSelectedMoreSubOption(data?.selectedSubOption);
      setFinalValues(data?.final);
    }
  }, []);

  return (
    <div>
      <div className="flex flex-row items-center gap-5 mb-6">
        <button
          className={`role-button ${
            selectedRoleType === "Leadership Role"
              ? "bg-[#e8e2ff] px-14 py-3 text-[#00519D] font-medium text-[24px] rounded-[10px] shadow-sm shadow-[#00000040]"
              : "bg-[#F8F6FF] px-14 py-3 text-[#00519D] font-medium text-[24px] rounded-[10px] shadow-sm shadow-[#00000040]"
          }`}
          onClick={() => handleRoleTypeSelection("Leadership Role")}
        >
          Leadership Role
        </button>
        <button
          className={`role-button ${
            selectedRoleType === "Non Leadership Role"
              ? "bg-[#e8e2ff] px-14 py-3 text-[#00519D] font-medium text-[24px] rounded-[10px] shadow-sm shadow-[#00000040]"
              : "bg-[#F8F6FF] px-14 py-3 text-[#00519D] font-medium text-[24px] rounded-[10px] shadow-sm shadow-[#00000040]"
          }`}
          onClick={() => handleRoleTypeSelection("Non Leadership Role")}
        >
          Non-Leadership Role
        </button>
      </div>

      {selectedRoleType === "Leadership Role" && (
        <div className="my-4 flex flex-row ">
          {impactData[selectedRoleType].options.map(role => (
            <div className="">
              <Radio.Group
                onChange={e => handleRoleSelection(role.key, role)}
                value={selectedRole}
              >
                <Radio className="text-lg font-medium" value={role.key}>
                  {role.display_name}
                </Radio>
              </Radio.Group>

              <div className="sub-options flex flex-col my-2">
                {role.sub_options.map(subOption => (
                  <div>
                    <div key={subOption.key} className="flex items-center my-2">
                      <Radio
                        className="text-lg font-medium mr-2"
                        key={subOption.key}
                        value={subOption.key}
                        disabled={selectedRole !== role.key}
                        onChange={() =>
                          handleSubOptionSelection(subOption.key, subOption)
                        }
                        checked={selectedSubOption === subOption.key}
                      >
                        {subOption.display_name}
                      </Radio>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedRoleType === "Leadership Role" && selectedSubOption !== null && (
        <div className="more-sub-options flex flex-row mt-2 gap-4">
          {impactData[selectedRoleType].options
            .find(option => option.key === selectedRole)
            ?.sub_options.find(option => option.key === selectedSubOption)
            ?.more_sub_options_ref.map(moreSubOption => (
              <button
                key={moreSubOption.key}
                className={`px-10 py-2 font-medium rounded-[8px] ${
                  selectedMoreSubOption === moreSubOption.key
                    ? "bg-[#e8e2ff] text-[#00519D] mt-2"
                    : "bg-[#F8F6FF] text-[#00519D] mt-2"
                }`}
                onClick={() =>
                  handleMoreSubOptionSelection(moreSubOption.key, moreSubOption)
                }
              >
                {moreSubOption.display_name}
              </button>
            ))}
        </div>
      )}

      {final && (
        <p className="text-lg font-semibold mt-4">
          {/* The organization level is: {final} */}
        </p>
      )}

      {selectedRoleType === "Non Leadership Role" && (
        <div className="flex items-center gap-4">
          {impactData[selectedRoleType].options.map(role => (
            <div key={role.key} className="role-option">
              <button
                className={`px-14 py-3 font-medium text-24px rounded-[10px] shadow-sm shadow-[#00000040] ${
                  selectedRole === role.key
                    ? "bg-[#e8e2ff] text-[#00519D]"
                    : "bg-[#F8F6FF] text-[#00519D]"
                }`}
                onClick={() => handleRoleSelection1(role.key, role)}
              >
                {role.display_name}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CreateJobEvaluation = () => {
  const [completionModal, setcompletionModal] = useState(false);
  const factors: Factors = { ...factorsJSON };
  const dispatch = useDispatch();
  const history = useHistory();
  const [fileListOrgChart, setFileListOrgChart] = useState<any[]>([]);
  const [fileListJEform, setFileListJEform] = useState<any[]>([]);
  const [toggle, settoggle] = useState(false);
  const handleToggleTrue = () => {
    settoggle(true);
  };
  const handleToggleFalse = () => {
    settoggle(false);
  };

  const [uploading, setUploading] = useState(false);
  const [uploadJobEvaluationForms, { isLoading }] =
    useUploadJobEvaluationFormsMutation();

  const handleUpload = async () => {
    const formData = new FormData();
    fileListOrgChart.forEach(file => {
      formData.append("organization_chart", file);
    });

    fileListJEform.forEach(file => {
      formData.append("je_form", file);
    });

    try {
      const payload = await uploadJobEvaluationForms({
        company_id,
        job_evaluation_id,
        body: formData,
      }).unwrap();

      if (payload.message) {
        message.success(payload.message);
        // dispatch(updateValidationId(payload.result));
        // dispatch(resetEquipmentId());
        // setFileList([]);
      }
    } catch (error) {
      message.error("Unable to process request");
    }
  };

  const uploadOrgChartProps: UploadProps = {
    onRemove: (file: any) => {
      const index = fileListOrgChart.indexOf(file);
      const newFileList = fileListOrgChart.slice();
      newFileList.splice(index, 1);
      setFileListOrgChart(newFileList);
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
        return false;
      }
      if (fileListOrgChart.length) {
        message.warning("Only one file can be uploaded at a time");
        return false;
      }
      fileListOrgChart.length === 0 &&
        setFileListOrgChart([...fileListOrgChart, file]);
      return false;
    },
    fileList: fileListOrgChart,
  };

  const uploadJEformProps: UploadProps = {
    onRemove: (file: any) => {
      const index = fileListJEform.indexOf(file);
      const newFileList = fileListJEform.slice();
      newFileList.splice(index, 1);
      setFileListJEform(newFileList);
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
        return false;
      }
      if (fileListJEform.length) {
        message.warning("Only one file can be uploaded at a time");
        return false;
      }
      fileListJEform.length === 0 &&
        setFileListJEform([...fileListJEform, file]);

      return false;
    },
    fileList: fileListJEform,
  };

  const { user } = useTypedSelector(state => state.auth);

  const company_id = user?.company?.id || 0;
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};

  const {
    evaluationInformation,
    jobDetails,
    knowledgeSkillAndApplication,
    problemSolvingSolutioning,
    stakeHolderManagement,
    decisionMakingImpact,
    financialResponsibility,
    id: jobDetails_id,
    status,
    je_form,
    organization_chart,
  } = useTypedSelector(state => state?.jobEvaluation);
  const {
    jobTitle,
    job_function,
    sub_job_function,
    evaluation_model,
    region,
    business_unit,
    country_id,
  } = jobDetails;

  const {
    knowledge_and_skill,
    application,
    minimum_level_education,
    minimum_level_education_reason,
    educational_degree,
  } = knowledgeSkillAndApplication;
  const { problem_solving_solutioning } = problemSolvingSolutioning;
  const { communication_nature, complexity } = stakeHolderManagement;
  const { role_impact_on_organisation, impact_of_decision } =
    decisionMakingImpact;
  const { non_financial_responsibility, revenue_responsibility } =
    financialResponsibility;

  const disabled =
    !knowledge_and_skill?.key ||
    !application?.key ||
    !minimum_level_education ||
    !minimum_level_education_reason ||
    !educational_degree ||
    !problem_solving_solutioning?.key ||
    !communication_nature?.key ||
    !complexity?.key ||
    !role_impact_on_organisation?.key ||
    !impact_of_decision?.key ||
    !evaluation_model?.key ||
    (evaluation_model?.key === EvaluationModelKeys.NON_FINANCIAL &&
      !non_financial_responsibility?.key) ||
    (evaluation_model?.key !== EvaluationModelKeys.NON_FINANCIAL &&
      !revenue_responsibility?.key);

  const {
    data: knowledgeAndSkillsData,
    isLoading: isLoadingknowledgeAndSkillsData,
  } = useFetchAllKnowledgeAndSkillsQuery(null);
  const { data: knowledgeAndSkillOptions } = knowledgeAndSkillsData || {
    data: [],
  };

  // console.log(
  //   [
  //     ...knowledgeAndSkillOptions,
  //     {
  //       display_name: "Multi Specialization",
  //       key: "multi_specialization",
  //     },
  //   ],
  //   "sfsf"
  // );

  const { data: applicationsData, isLoading: isLoadingApplicationsData } =
    useFetchAllApplicationsQuery(null);
  const { data: applicationOptions } = applicationsData || { data: [] };

  const {
    data: problemSolvingAndSolutioningData,
    isLoading: isLoadingProblemSolvingAndSolutioningData,
  } = useFetchAllProblemSolvingAndSolutioningQuery(null);
  const { data: problemSolvingAndSolutioningOptions } =
    problemSolvingAndSolutioningData || {
      data: [],
    };

  const {
    data: natureOfCommunicationData,
    isLoading: isLoadingNatureOfCommunicationData,
  } = useFetchAllNatureOfCommunicationQuery(null);
  const { data: natureOfCommunicationOptions } = natureOfCommunicationData || {
    data: [],
  };

  const { data: complexityData, isLoading: isLoadingComplexityData } =
    useFetchAllComplexitiesQuery(null);
  const { data: complexityOptions } = complexityData || {
    data: [],
  };

  const {
    data: roleImpactOnOrganisationData,
    isLoading: isLoadingRoleImpactOnOrganisationData,
  } = useFetchAllRoleImpactOnOrganisationQuery(null);
  const { data: roleImpactOnOrganisationOptions } =
    roleImpactOnOrganisationData || {
      data: [],
    };

  const {
    data: impactOfDecisionMakingData,
    isLoading: isLoadingImpactOfDecisionMakingData,
  } = useFetchAllImpactOfDecisionMakingQuery(null);
  const { data: impactOfDecisionMakingOptions } =
    impactOfDecisionMakingData || {
      data: [],
    };

  const {
    data: nonFinancialResponsibilityData,
    isLoading: isLoadingNonFinancialResponsibilityData,
  } = useFetchAllNonFinancialResponsibilityQuery(null);
  const { data: nonFinancialResponsibilityOptions } =
    nonFinancialResponsibilityData || {
      data: [],
    };

  const {
    data: revenueResponsibilityData,
    isLoading: isLoadingRevenueResponsibility,
  } = useFetchAllRevenueResponsibilityQuery(null);
  const { data: revenueResponsibilityOptions } = revenueResponsibilityData || {
    data: [],
  };

  let finalRevenueResponsibility = [...revenueResponsibilityOptions]
    .sort((a: { display_name: string }, b: { display_name: string }) => {
      const getValue = (str: string): number => {
        // Determine the unit (billion or million) based on the suffix
        const unit = str.includes("b") ? 1e9 : str.includes("m") ? 1e6 : 1;
        // Remove '<', '>', 'm', and 'b' characters, and convert the remaining string to a float
        return parseFloat(str.replace(/[<>mb]/g, "")) * unit;
      };

      // Compare the numeric values extracted from display_name
      const valueA = getValue(a.display_name);
      const valueB = getValue(b.display_name);

      return valueA - valueB;
    })
    .map(({ key, display_name }: { key: string; display_name: string }) => ({
      id: key,
      name: display_name,
    }));

  const job_evaluation_id = jobDetails_id || 0;

  const [updateJobEvaluation, { isLoading: isLoadingUpdateJobEvaluation }] =
    useUpdateJobEvaluationMutation();
  const [createJobEvaluation, { isLoading: isLoadingCreateJobEvaluation }] =
    useCreateJobEvaluationMutation();

  const handleChangeJobDetailsStep = (event: any) => {
    dispatch(
      handleJobDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleChangeKnowledgeSkillStep = (event: any) => {
    if (event.target.name == "educational_degree") {
      dispatch(
        handleKnowledgeSkillAndApplication({
          ["minimum_level_education_reason"]: "-",
        })
      );

      dispatch(
        handleKnowledgeSkillAndApplication({
          ["minimum_level_education"]: "-",
        })
      );
    }
    dispatch(
      handleKnowledgeSkillAndApplication({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeProblemSolvingStep = (event: any) => {
    dispatch(
      handleProblemSolvingSolutioning({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeStakeHolderManagementStep = (event: any) => {
    dispatch(
      handleStakeHolderManagement({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeDecisionMakingStep = (event: any) => {
    dispatch(
      handleDecisionMakingImpact({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeFinancialResponsibilityStep = (event: any) => {
    dispatch(
      handleFinancialResponsibility({
        [event.target.name]: event.target.value,
      })
    );
  };

  useEffect(() => {
    const body = {
      job_title: jobDetails.jobTitle || undefined,
      department_name: jobDetails.departmentName || undefined,
      evaluation_model: jobDetails.evaluation_model?.key || undefined,
      no_direct_report: jobDetails.noOfDirectReports || undefined,
      business_unit: jobDetails?.business_unit,
      country: jobDetails?.country_id,
      region: jobDetails?.region,
      knowledge_skills:
        knowledgeSkillAndApplication.knowledge_and_skill?.key || undefined,
      knowledge_skills_application:
        knowledgeSkillAndApplication.application?.key || undefined,
      minimum_level_of_education_required:
        knowledgeSkillAndApplication.minimum_level_education || undefined,
      why_is_this_level_of_education_required:
        knowledgeSkillAndApplication.minimum_level_education_reason ||
        undefined,
      area_of_specializations_or_degree:
        knowledgeSkillAndApplication.educational_degree || undefined,
      problem_solving_solutioning:
        problemSolvingSolutioning.problem_solving_solutioning?.key || undefined,
      nature_of_communication:
        stakeHolderManagement.communication_nature?.key || undefined,
      complexity: stakeHolderManagement.complexity?.key || undefined,
      role_impact_on_organization:
        decisionMakingImpact.role_impact_on_organisation?.key || undefined,
      impact_of_decision_making:
        decisionMakingImpact.impact_of_decision?.key || undefined,
      non_financial_responsibility:
        financialResponsibility.non_financial_responsibility?.key || undefined,
      revenue_responsibility:
        financialResponsibility.revenue_responsibility?.key || undefined,
    };
    if (company_id && job_evaluation_id) {
      updateJobEvaluation({
        company_id,
        job_evaluation_id,
        body,
      }).unwrap();
    }
  }, [
    dispatch,
    jobDetails,
    evaluationInformation,
    knowledgeSkillAndApplication,
    problemSolvingSolutioning,
    stakeHolderManagement,
    decisionMakingImpact,
    financialResponsibility,
  ]);

  const fetchDataFromLocalStorage = (key: any) => {
    const storedData = JSON.parse(localStorage.getItem("jobData") || "{}");
    return storedData;
  };
  const removeDataFromLocalStorage = (key: any) => {
    let storedData = fetchDataFromLocalStorage(key);
    delete storedData[key];
    localStorage.setItem("jobData", JSON.stringify(storedData));
  };

  const onSubmit = async () => {
    try {
      const body: IUpdateJobEvaluation = {
        job_title: jobDetails.jobTitle || undefined,
        department_name: jobDetails.departmentName || undefined,
        evaluation_model: jobDetails.evaluation_model?.key || undefined,
        no_direct_report: jobDetails.noOfDirectReports || undefined,
        business_unit: jobDetails?.business_unit,
        country: jobDetails?.country_id,
        region: jobDetails?.region,
        knowledge_skills:
          knowledgeSkillAndApplication.knowledge_and_skill?.key || undefined,
        knowledge_skills_application:
          knowledgeSkillAndApplication.application?.key || undefined,
        minimum_level_of_education_required:
          knowledgeSkillAndApplication.minimum_level_education || undefined,
        why_is_this_level_of_education_required:
          knowledgeSkillAndApplication.minimum_level_education_reason ||
          undefined,
        area_of_specializations_or_degree:
          knowledgeSkillAndApplication.educational_degree || undefined,
        problem_solving_solutioning:
          problemSolvingSolutioning.problem_solving_solutioning?.key ||
          undefined,
        nature_of_communication:
          stakeHolderManagement.communication_nature?.key || undefined,
        complexity: stakeHolderManagement.complexity?.key || undefined,
        role_impact_on_organization:
          decisionMakingImpact.role_impact_on_organisation?.key || undefined,
        impact_of_decision_making:
          decisionMakingImpact.impact_of_decision?.key || undefined,
        non_financial_responsibility:
          financialResponsibility.non_financial_responsibility?.key ||
          undefined,
        revenue_responsibility:
          financialResponsibility.revenue_responsibility?.key || undefined,
      };

      const jobEvaluationResp = await createJobEvaluation({
        company_id,
        job_evaluation_id,
        body,
      }).unwrap();
      const statue = status;
      const jobId = jobDetails_id ? jobDetails_id.toString() : "";
      jobId && history.push(paths.client.jobEvaluation.summary(jobId));

      removeDataFromLocalStorage(jobDetails_id);

      // yaha krna h
      // setcompletionModal(true)
      showSuccessPopup({
        title: "Job Evaluation Created",
        desc: `You have successfully evaluated a job. Job Grade : ${jobEvaluationResp?.data?.evaluation_result}`,
      });
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const gridStyle: React.CSSProperties = {
    width: "50%",
    textAlign: "left",
  };

  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};

  const steps = [
    {
      title: "Evaluation Information",
      // status: 'finish',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
    {
      title: "Job details",
      // status: 'finish',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
    {
      title: "Knowledge, Skills and Application",
      // status: 'process',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
    {
      title: "Problem Solving and Solutioning",
      // status: 'wait',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
    {
      title: "StakeHolder Management",
      // status: 'wait',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
    {
      title: "Decision Making And Impact",
      // status: 'wait',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
    {
      title: "Finantical Responsibility",
      // status: 'wait',
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
      // icon: <StepperIconWrpper><FaAddressCard /></StepperIconWrpper>,
    },
  ];

  const [current, setCurrent] = useState(1);

  const stepConfigurations = [
    { name: "1", fields: ["evaluation_model"] },
    {
      name: "2",
      fields: [
        "department_name",
        "job_function",
        "sub_job_function",
        "business_unit",
        "region",
        "country",
      ],
    },
    {
      name: "3",
      fields: [
        "knowledge_skills",
        "knowledge_skills_application",
        "minimum_level_of_education_required",
        "why_is_this_level_of_education_required",
        "area_of_specializations_or_degree",
      ],
    },

    {
      name: "4",
      fields: ["problem_solving_solutioning"],
    },

    {
      name: "5",
      fields: ["nature_of_communication", "complexity"],
    },
    {
      name: "6",
      fields: ["role_impact_on_organization", "impact_of_decision_making"],
    },
    {
      name: "7",
      fields: [
        jobDetails?.evaluation_model?.key === "financial"
          ? "revenue_responsibility"
          : "non_financial_responsibility",
      ],
    },
  ];

  const validateStep = (stepFields: string[]) => {
    let data: any = {
      job_title: jobDetails.jobTitle || undefined,
      department_name: jobDetails.departmentName || undefined,
      evaluation_model: jobDetails.evaluation_model?.key || undefined,
      no_direct_report: jobDetails.noOfDirectReports || undefined,
      business_unit: jobDetails?.business_unit,
      country: jobDetails?.country_id,
      job_function: jobDetails?.job_function,
      sub_job_function: jobDetails?.sub_job_function,
      region: jobDetails?.region,
      knowledge_skills:
        knowledgeSkillAndApplication.knowledge_and_skill?.key || undefined,
      knowledge_skills_application:
        knowledgeSkillAndApplication.application?.key || undefined,
      minimum_level_of_education_required:
        knowledgeSkillAndApplication.minimum_level_education || undefined,
      why_is_this_level_of_education_required:
        knowledgeSkillAndApplication.minimum_level_education_reason ||
        undefined,
      area_of_specializations_or_degree:
        knowledgeSkillAndApplication.educational_degree || undefined,
      problem_solving_solutioning:
        problemSolvingSolutioning.problem_solving_solutioning?.key || undefined,
      nature_of_communication:
        stakeHolderManagement.communication_nature?.key || undefined,
      complexity: stakeHolderManagement.complexity?.key || undefined,
      role_impact_on_organization:
        decisionMakingImpact.role_impact_on_organisation?.key || undefined,
      impact_of_decision_making:
        decisionMakingImpact.impact_of_decision?.key || undefined,
      non_financial_responsibility:
        financialResponsibility.non_financial_responsibility?.key || undefined,
      revenue_responsibility:
        financialResponsibility.revenue_responsibility?.key || undefined,
    };

    for (const field of stepFields) {
      if (!data[field]) {
        return false;
      }
    }
    return true;
  };

  // Function to determine if all fields for the current step are filled
  const isStepComplete = () => {
    const stepFields = stepConfigurations[current - 1].fields;
    return validateStep(stepFields);
  };

  const next = () => {
    if (current + 1 <= steps.length && isStepComplete()) {
      setCurrent(current + 1);
    } else {
      onSubmit();
      // setcompletionModal(true)
    }
  };
  const prev = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };




  return (
    <JobEvaluationDataProvider>
      <div className=" overflow-x-hidden transition-all delay-75  overflow-y-auto fixed left-20 top-10 inset-0 z-50 outline-none focus:outline-none px-16">
        {/* modal starts */}
        {/* <div className="fixed z-10 inset-0 overflow-y-auto ">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom shadow-[#33dd33] bg-white rounded-lg text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg h-40 absolute -z-40  -top-10 -left-2 transform -rotate-6 bg-[#EEEEEE] w-full"></div>
              <div className="bg h-[90px] -z-40 absolute top-0 left-0  bg-[#EEEEEE] w-full"></div>
              <div className="smileyBg z-50 mt-20">
                <div className="smiley w-40 mx-auto">
                  <img src={smiley} className="h-full w-full" alt="smiley" />
                </div>
              </div>
              <div className="content text-center mt-10">
                <div className="title font-bold text-2xl">Job Creation Completed</div>
                <div className="desc text-lg mt-5">You have just successfully created a Job for your organization.</div>
              </div>

              <div className="cta flex justify-center mt-10 mb-5">
                <button onClick={() => setcompletionModal(false)} className="px-4 py-2 bg-[#33dd33] rounded-md font-bold text-lg text-white mx-auto">Continue</button>
              </div>
            </div>
          </div>
        </div> */}
        {/* modal ends */}
        <div className="relative my-6 mx-auto py-10 w-full">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full    bg-themeCore outline-none focus:outline-none">
            <div className="relative py-6 px-24 flex-auto">
              <div className="sticky top-0 z-10 bg-white px-6 rounded-md shadow-md py-4 ">
                <Stepper
                  steps={steps}
                  current={current}
                  setCurrent={setCurrent}
                />
              </div>

              <div className="content mt-14 p-5 border z-50">
                {current === 1 ? (
                  <div className="form1">
                    <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                      Upload Job Evaluation Form and Organizational Chart
                    </h3>

                    {je_form && je_form.length ? (
                      <div className="flex items-center justify-start">
                        <p>Je Form</p>

                        <Button
                          type="primary"
                          className="mt-6"
                          icon={<DownloadOutlined />}
                          onClick={() => window.open(`${je_form}`, "_blank")}
                        />
                      </div>
                    ) : (
                      <div className="uploadBox mt-14 rounded-lg py-5 bg-white shadow-lg my-10 px-2">
                        <div className="head border-b p-5">
                          <div className="toggle rounded-full bg-themeCore w-fit mx-auto">
                            <button
                              onClick={handleToggleFalse}
                              className={`px-5 py-2 font-semibold rounded-full pointer ${
                                toggle === false
                                  ? "bg-themeBlue text-white"
                                  : ""
                              }`}
                            >
                              New Upload
                            </button>
                            <button
                              disabled
                              onClick={handleToggleTrue}
                              className={`px-5 py-2 font-semibold rounded-full pointer  ${
                                toggle === true
                                  ? "bg-themeBlue text-white"
                                  : " text-gray-400"
                              }`}
                            >
                              Recent
                            </button>
                          </div>
                        </div>
                        <div className="sections">
                          {toggle ? (
                            <div className="recent">
                              <ListComponent />
                              <div className="footer flex gap-2 items-center bg-themeCore p-5 rounded-b-[2.2rem]">
                                <IoIosCheckmarkCircleOutline />
                                <p>Last Synced: 3 mins ago</p>
                              </div>
                            </div>
                          ) : (
                            <div className="fileUpload">
                              <Dragger {...uploadJEformProps} maxCount={1}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload JE
                                  Form
                                </p>
                                <p className="ant-upload-hint">
                                  Support for a single or bulk upload. Strictly
                                  prohibited from uploading company data or
                                  other banned files.
                                </p>
                              </Dragger>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {organization_chart && organization_chart.length ? (
                      <div className="flex items-center justify-start">
                        <p>Organization Chart</p>

                        <Button
                          type="primary"
                          className="mt-6"
                          icon={<DownloadOutlined />}
                          onClick={() =>
                            window.open(`${organization_chart}`, "_blank")
                          }
                        />
                      </div>
                    ) : (
                      je_form &&
                      je_form.length && (
                        <div className="uploadBox mt-14 rounded-lg py-5 bg-white shadow-lg my-10 px-2">
                          <div className="head border-b p-5">
                            <div className="toggle rounded-full bg-themeCore w-fit mx-auto">
                              <button
                                onClick={handleToggleFalse}
                                className={`px-5 py-2 font-semibold rounded-full pointer ${
                                  toggle === false
                                    ? "bg-themeBlue text-white"
                                    : ""
                                }`}
                              >
                                New Upload
                              </button>
                              <button
                                disabled
                                onClick={handleToggleTrue}
                                className={`px-5 py-2 font-semibold rounded-full pointer  ${
                                  toggle === true
                                    ? "bg-themeBlue text-white"
                                    : " text-gray-400"
                                }`}
                              >
                                Recent
                              </button>
                            </div>
                          </div>
                          <div className="sections">
                            {toggle ? (
                              <div className="recent">
                                <ListComponent />
                                <div className="footer flex gap-2 items-center bg-themeCore p-5 rounded-b-[2.2rem]">
                                  <IoIosCheckmarkCircleOutline />
                                  <p>Last Synced: 3 mins ago</p>
                                </div>
                              </div>
                            ) : (
                              <div className="fileUpload">
                                <Dragger {...uploadOrgChartProps} maxCount={1}>
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                    Organizational Chart
                                  </p>
                                  <p className="ant-upload-hint">
                                    Support for a single or bulk upload.
                                    Strictly prohibited from uploading company
                                    data or other banned files.
                                  </p>
                                </Dragger>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    )}

                    {fileListOrgChart.length > 0 ||
                      (fileListJEform.length > 0 && (
                        <CustomButton
                          onClick={() => {
                            handleUpload();
                          }}
                          disabledProp={
                            fileListOrgChart.length === 0 &&
                            fileListJEform.length === 0
                          }
                          btnName={isLoading ? "Uploading" : "Start Upload"}
                        />
                      ))}

                    {evaluationModelOptions1 && (
                      <CustomFormSelect
                        disabled={false}
                        title="Evaluation Model"
                        onChange={option => {
                          handleChangeJobDetailsStep({
                            target: {
                              name: "evaluation_model",
                              value: {
                                key: option?.id,
                                display_name: option?.name,
                              },
                            },
                          });

                          if (
                            option?.id.toString() ===
                            EvaluationModelKeys.Financial
                          ) {
                            dispatch(
                              handleFinancialResponsibility({
                                non_financial_responsibility: null,
                              })
                            );
                          }
                        }}
                        value={evaluation_model?.key || ""}
                        placeholder="Select evaluation model from here..."
                        options={evaluationModelOptions1}
                      />
                    )}
                  </div>
                ) : current === 2 ? (
                  <div className="form2">
                    <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                      Job Details
                    </h3>
                    <div className="jobDetailsInput mt-16 flex gap-5 flex-wrap">
                      <CustomInput
                        name="jobTitle"
                        title="Department Name"
                        value={jobTitle || ""}
                        disabled={true}
                        onChange={() => {}}
                      />
                      {jobFunctions && (
                        <CustomFormSelect
                          disabled={true}
                          title="Job Function"
                          onChange={() => {}}
                          value={job_function?.id || undefined}
                          options={jobFunctions || []}
                        />
                      )}
                      <CustomFormSelect
                        disabled={true}
                        title="Job Sub-Function"
                        onChange={() => {}}
                        value={sub_job_function?.id || undefined}
                        options={[
                          {
                            id: sub_job_function?.id ?? 0,
                            name: sub_job_function?.name ?? "",
                          },
                        ]}
                      />
                      {businessUnits && (
                        <CustomFormSelect
                          disabled={true}
                          title="Business Unit"
                          onChange={() => {}}
                          value={business_unit || undefined}
                          options={businessUnits || []}
                        />
                      )}
                      {businessUnits && (
                        <CustomFormSelect
                          disabled={true}
                          title="Region"
                          onChange={() => {}}
                          value={region || undefined}
                          options={
                            businessUnits?.filter(
                              (elm: any) => elm.id === business_unit
                            )[0]?.regions || []
                          }
                        />
                      )}
                      {businessUnits && (
                        <CustomFormSelect
                          disabled={true}
                          title="Country"
                          onChange={() => {}}
                          value={country_id || undefined}
                          options={
                            businessUnits
                              ?.filter(
                                (elm: any) => elm.id === business_unit
                              )[0]
                              ?.regions?.filter(
                                (elm: any) => elm.id === region
                              )[0]?.countries || []
                          }
                        />
                      )}
                    </div>
                  </div>
                ) : current === 3 ? (
                  <div className="form3">
                    <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                      Knowledge, Skills and Application
                    </h3>
                    <div className="scroller mt-16 h-96 overflow-y-scroll">
                      <div className="knowledgeandskill">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Knowledge and Skills
                        </h3>
                        <div className="container grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-2 xl:gap-8 2xl:gap-14 mt-10 mx-auto">
                          {/* {knowledgeAndSkillOptions &&
                            [...knowledgeAndSkillOptions, {
                              display_name: "Multi Specialization",
                              key: "single_specialization"
                            }].map( */}


                          
                          {knowledgeAndSkillOptions &&
                            knowledgeAndSkillOptions.map((item: any, i: any) => {
                              return (
                                <div className="cardComponent" key={i}>
                                  <div className="card">
                                    <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                      {item?.display_name}
                                    </div>

                                    <div className="content flex flex-col justify-center items-center min-h-44 xl:min-h-80 rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                      <div className="text flex-grow flex justify-center items-center text-[14px]">
                                        {
                                          factors?.knowledgeAndSkillOptions[
                                            item?.display_name
                                          ].desc
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleChangeKnowledgeSkillStep({
                                        target: {
                                          name: "knowledge_and_skill",
                                          value: {
                                            key: item?.key,
                                            display_name: item?.display_name,
                                          },
                                        },
                                      });
                                    }}
                                    disabled={
                                      knowledge_and_skill?.key === item?.key
                                        ? true
                                        : false
                                    }
                                    className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                      knowledge_and_skill?.key === item?.key
                                        ? "bg-themeGrey"
                                        : ""
                                    }`}
                                  >
                                    {knowledge_and_skill?.key === item?.key
                                      ? "Selected"
                                      : "Select"}
                                  </button>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="application mt-20">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Application
                        </h3>

                        <div className="container grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-2 xl:gap-8 2xl:gap-14 mt-10 mx-auto">
                          {applicationOptions &&
                            applicationOptions.map((item: any, i: any) => {
                              return (
                                <div className="cardComponent" key={i}>
                                  <div className="card">
                                    <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                      {item?.display_name}
                                    </div>

                                    <div className="content flex flex-col justify-center items-center min-h-44 xl:min-h-80 rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                      <div className="text flex-grow flex justify-center items-center text-[14px]">
                                        {
                                          factors?.applicationOptions[
                                            item?.display_name
                                          ].desc
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleChangeKnowledgeSkillStep({
                                        target: {
                                          name: "application",
                                          value: {
                                            key: item?.key,
                                            display_name: item?.display_name,
                                          },
                                        },
                                      });
                                    }}
                                    disabled={
                                      application?.key === item?.key
                                        ? true
                                        : false
                                    }
                                    className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                      application?.key === item?.key
                                        ? "bg-themeGrey"
                                        : ""
                                    }`}
                                  >
                                    {application?.key === item?.key
                                      ? "Selected"
                                      : "Select"}
                                  </button>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <div className="jobDetailsInput mt-20 2xl:flex gap-5 flex-wrap">
                        <div className="left border w-full 2xl:w-[49%]">
                          {/* <CustomInput
                            customContainer="!w-[100%]"
                            type="textarea"
                            title="Minimum level of education required"
                            name={"minimum_level_education"}
                            onChange={handleChangeKnowledgeSkillStep}
                            value={minimum_level_education || ""}
                            placeholder="Enter here..."
                          /> */}

                          <CustomInput
                            customContainer="!w-[100%]"
                            title="Area of specialization/degree"
                            name={"educational_degree"}
                            onChange={handleChangeKnowledgeSkillStep}
                            value={educational_degree || ""}
                            placeholder="Enter here..."
                          />
                        </div>
                        <div className="right border w-full 2xl:w-[49%]">
                          {/* <CustomInput
                            customContainer="!w-[100%]"
                            title="Why is this level of education required"
                            name={"minimum_level_education_reason"}
                            onChange={handleChangeKnowledgeSkillStep}
                            value={minimum_level_education_reason || ""}
                          /> */}

                          {/*
                          <CustomInput
                            customContainer="!w-[100%]"
                            title="Area of specialization/degree"
                            name={"educational_degree"}
                            onChange={handleChangeKnowledgeSkillStep}
                            value={educational_degree || ""}
                            placeholder="Enter here..."
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : current === 4 ? (
                  <div className="form3">
                    <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                      Problem Solving And Solutioning
                    </h3>
                    <div className="scroller mt-16 h-96 overflow-y-scroll">
                      <div className="knowledgeandskill">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Problem Solving And Solutioning
                        </h3>
                        <div className="container grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-2 xl:gap-8 2xl:gap-14 mt-10 mx-auto">
                          {problemSolvingAndSolutioningOptions &&
                            problemSolvingAndSolutioningOptions.map(
                              (item: any, i: any) => {
                                return (
                                  <div className="cardComponent" key={i}>
                                    <div className="card">
                                      <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                        {item?.display_name ===
                                        "Highly Complex Unusual"
                                          ? "Highly Complex & Unusual"
                                          : item?.display_name}
                                      </div>

                                      <div className="content flex flex-col justify-center items-center min-h-52 xl:min-h-[380px] rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                        <div className="text flex-grow flex justify-center items-center text-[14px]">
                                          {
                                            factors
                                              ?.problemSolvingAndSolutioningOptions[
                                              item?.display_name
                                            ].desc
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleChangeProblemSolvingStep({
                                          target: {
                                            name: "problem_solving_solutioning",
                                            value: {
                                              key: item?.key,
                                              display_name: item?.display_name,
                                            },
                                          },
                                        });
                                      }}
                                      disabled={
                                        problem_solving_solutioning?.key ===
                                        item?.key
                                          ? true
                                          : false
                                      }
                                      className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                        problem_solving_solutioning?.key ===
                                        item?.key
                                          ? "bg-themeGrey"
                                          : ""
                                      }`}
                                    >
                                      {problem_solving_solutioning?.key ===
                                      item?.key
                                        ? "Selected"
                                        : "Select"}
                                    </button>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : current === 5 ? (
                  <div className="form3">
                    <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                      Stakeholder Management
                    </h3>
                    <div className="scroller mt-16 h-96 overflow-y-scroll">
                      <div className="knowledgeandskill">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Nature of Communication
                        </h3>
                        <div className="container grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-2 xl:gap-8 2xl:gap-14 mt-10 mx-auto">
                          {natureOfCommunicationOptions &&
                            natureOfCommunicationOptions.map(
                              (item: any, i: any) => {
                                return (
                                  <div className="cardComponent" key={i}>
                                    <div className="card">
                                      <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                        {item?.display_name}
                                      </div>

                                      <div className="content flex flex-col justify-center items-center min-h-44 xl:min-h-80 rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                        <div className="text flex-grow flex justify-center items-center text-[14px]">
                                          {
                                            factors
                                              ?.natureOfCommunicationOptions[
                                              item?.display_name
                                            ].desc
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleChangeStakeHolderManagementStep({
                                          target: {
                                            name: "communication_nature",
                                            value: {
                                              key: item?.key,
                                              display_name: item?.display_name,
                                            },
                                          },
                                        });
                                      }}
                                      disabled={
                                        communication_nature?.key === item?.key
                                          ? true
                                          : false
                                      }
                                      className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                        communication_nature?.key === item?.key
                                          ? "bg-themeGrey"
                                          : ""
                                      }`}
                                    >
                                      {communication_nature?.key === item?.key
                                        ? "Selected"
                                        : "Select"}
                                    </button>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                      <div className="application mt-20">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Complexity
                        </h3>

                        <div className="container grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-2 xl:gap-8 2xl:gap-14 mt-10 mx-auto">
                          {complexityOptions &&
                            complexityOptions.map((item: any, i: any) => {
                              return (
                                <div className="cardComponent" key={i}>
                                  <div className="card">
                                    <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                      {item?.display_name}
                                    </div>

                                    <div className="content flex flex-col justify-center items-center min-h-44 xl:min-h-80 rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                      <div className="text flex-grow flex justify-center items-center text-[14px]">
                                        {
                                          factors?.complexityOptions[
                                            item?.display_name
                                          ].desc
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleChangeStakeHolderManagementStep({
                                        target: {
                                          name: "complexity",
                                          value: {
                                            key: item?.key,
                                            display_name: item?.display_name,
                                          },
                                        },
                                      });
                                    }}
                                    disabled={
                                      complexity?.key === item?.key
                                        ? true
                                        : false
                                    }
                                    className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                      complexity?.key === item?.key
                                        ? "bg-themeGrey"
                                        : ""
                                    }`}
                                  >
                                    {complexity?.key === item?.key
                                      ? "Selected"
                                      : "Select"}
                                  </button>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : current === 6 ? (
                  <div className="form3">
                    <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                      Decision Making and Impact
                    </h3>
                    <div className="scroller mt-16 h-96 overflow-y-scroll">
                      <div className="knowledgeandskill">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Role Impact on Organization
                        </h3>
                        <div className="container mt-10 mx-auto">
                          {/* {roleImpactOnOrganisationOptions &&
                            roleImpactOnOrganisationOptions.map(
                              (item: any, i: any) => {
                                return (
                                  <div className="cardComponent" key={i}>
                                    <div className="card">
                                      <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                        {item?.display_name}
                                      </div>

                                      <div className="content flex flex-col justify-center items-center min-h-44 xl:min-h-80 rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                        <div className="text flex-grow flex justify-center items-center text-[14px]">
                                          Knowledge of framework, rules and
                                          procedures within one specialty. Must
                                          require some groundwork and business
                                          related knowledge. Has a basic
                                          understanding of areas of outside of
                                          main specialization.
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleChangeDecisionMakingStep({
                                          target: {
                                            name: "role_impact_on_organisation",
                                            value: {
                                              key: item?.key,
                                              display_name: item?.display_name,
                                            },
                                          },
                                        });
                                      }}
                                      disabled={
                                        role_impact_on_organisation?.key ===
                                        item?.key
                                          ? true
                                          : false
                                      }
                                      className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                        role_impact_on_organisation?.key ===
                                        item?.key
                                          ? "bg-themeGrey"
                                          : ""
                                      }`}
                                    >
                                      {role_impact_on_organisation?.key ===
                                      item?.key
                                        ? "Selected"
                                        : "Select"}
                                    </button>
                                  </div>
                                );
                              }
                            )} */}

                          <RoleComponent
                            jobKey={jobDetails_id}
                            getKeyandUpdateData={item => {
                              let finalItem =
                                roleImpactOnOrganisationOptions[item?.id];

                              handleChangeDecisionMakingStep({
                                target: {
                                  name: "role_impact_on_organisation",
                                  value: {
                                    key: finalItem?.key,
                                    display_name: finalItem?.display_name,
                                  },
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="application mt-20">
                        <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                          Impact of Decision Making
                        </h3>

                        <div className="container grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-2 xl:gap-8 2xl:gap-14 mt-10 mx-auto">
                          {impactOfDecisionMakingOptions &&
                            impactOfDecisionMakingOptions.map(
                              (item: any, i: any) => {
                                return (
                                  <div className="cardComponent" key={i}>
                                    <div className="card">
                                      <div className="head font-semibold px-4 py-4 rounded-lg shadow-md text-themeBlue text-center lg:text-sm 2xl:text-sm border bg-[#E8E2FF] flex justify-center items-center h-16">
                                        {/* {item?.display_name} */}
                                        {
                                          factors
                                            ?.impactOfDecisionMakingOptions[
                                            item?.display_name
                                          ].name
                                        }
                                      </div>

                                      <div className="content flex flex-col justify-center items-center min-h-44 xl:min-h-80 rounded-md shadow-md bg-white my-2 text-center text-themeGrey font-light p-2 border">
                                        <div className="text flex-grow flex justify-center items-center text-[14px]">
                                          {
                                            factors
                                              ?.impactOfDecisionMakingOptions[
                                              item?.display_name
                                            ].desc
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleChangeDecisionMakingStep({
                                          target: {
                                            name: "impact_of_decision",
                                            value: {
                                              key: item?.key,
                                              display_name: item?.display_name,
                                            },
                                          },
                                        });
                                      }}
                                      disabled={
                                        impact_of_decision?.key === item?.key
                                          ? true
                                          : false
                                      }
                                      className={`bg-[#714EF5] px-2 py-3 block w-[99%] mt-6 mx-auto text-lg text-white rounded-md ${
                                        impact_of_decision?.key === item?.key
                                          ? "bg-themeGrey"
                                          : ""
                                      }`}
                                    >
                                      {impact_of_decision?.key === item?.key
                                        ? "Selected"
                                        : "Select"}
                                    </button>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  current === 7 && (
                    <div>
                      <div className="form7">
                        {jobDetails?.evaluation_model?.key === "financial" ? (
                          <div>
                            <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                              Financial Responsibility
                            </h3>
                            <div className="scroller mt-16 h-96 overflow-y-scroll">
                              {revenueResponsibilityOptions && (
                                <CustomScopeFormSelect
                                  disabled={false}
                                  title="Scope of Accountability"
                                  onChange={option => {
                                    handleChangeFinancialResponsibilityStep({
                                      target: {
                                        name: "revenue_responsibility",
                                        value: {
                                          key: option?.id,
                                          display_name: option?.name,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    revenue_responsibility?.key || undefined
                                  }
                                  placeholder="Select evaluation model from here..."
                                  options={finalRevenueResponsibility}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                              Non Financial Responsibility
                            </h3>
                            <div className="scroller mt-16 h-96 overflow-y-scroll">
                              {nonFinancialResponsibilityOptions && (
                                <CustomFormSelect
                                  disabled={false}
                                  title="Scope of Accountability"
                                  onChange={option => {
                                    handleChangeFinancialResponsibilityStep({
                                      target: {
                                        name: "non_financial_responsibility",
                                        value: {
                                          key: option?.id,
                                          display_name: option?.name,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    non_financial_responsibility?.key ||
                                    undefined
                                  }
                                  placeholder="Select evaluation model from here..."
                                  options={nonFinancialResponsibilityOptions.map(
                                    ({
                                      key,
                                      display_name,
                                    }: {
                                      key: string;
                                      display_name: string;
                                    }) => ({ id: key, name: display_name })
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex items-center gap-5 justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                // disabled={true}
                onClick={prev}
                className={`block bg-themeBlue text-white py-2 px-10 rounded-md ${
                  current == 1 ? "!bg-gray-400 cursor-default" : ""
                }`}
              >
                Go Back
              </button>

              <button
                disabled={!isStepComplete()}
                onClick={next}
                className={`block bg-themeBlue text-white py-2 px-10 rounded-md ${
                  !isStepComplete() ? "!bg-gray-400 cursor-default" : ""
                }`}
              >
                Continue
              </button>
              {/* <CustomButton
                btnName="Continue"
                onClick={next}
              /> */}
              <CustomButton
                customClasses="!px-14"
                btnName="Close"
                onClick={() => history.push(paths.client.jobEvaluation.listing)}
              />

              {/* <CustomButton btnName="Close" onClick={onClose} /> */}
            </div>
          </div>
        </div>
      </div>
    </JobEvaluationDataProvider>
  );
};

export default CreateJobEvaluation;
