import { useState } from "react";
import { Col, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./jobGradeDefination.less";
import Table from "@components/Table";
import { handlePageChange, resetJobDetails } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";
import AddJobGradeDefination from "./AddJobGradeDefination";
import {
  useFetchAllGradePointsForCompaniesQuery,
  useFetchAllJobGradeMappingCompaniesQuery,
} from "@/services/jobGradeMapping";
import CustomButton from "@/components/customButton";
import SearchBox from "@/components/searchInput";

const JobGradeDefination = () => {
  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [page, setPage] = useState(1);

  const { data: JobGradeMappingCompaniesData } =
    useFetchAllJobGradeMappingCompaniesQuery(null);
  const { data } = JobGradeMappingCompaniesData || { data: [] };

  const {
    data: GradePointsForCompaniesData,
    isLoading: isLoadingGradePointsForCompanies,
  } = useFetchAllGradePointsForCompaniesQuery(null);
  const { data: gradePointsForCompany, pagination } =
    GradePointsForCompaniesData || { data: [] };

  const columns = [
    {
      title: "GRADE POINTS",
      key: "display_name",
      width: 200,
      render: (record: any) => (
        <div className="text-wrap">{record.grade_point_range.display_name}</div>
      ),
    },
    ...data.map((eachCompany: { id: string; name: string }) => {
      return {
        title: eachCompany.name,
        key: eachCompany["id"].toString(),
        width: 200,
        render: (record: any) => {
          return <div className="text-wrap">{record[eachCompany["id"]]}</div>;
        },
      };
    }),
  ];

  const onCloseModal = () => {
    setIsVisible(false);
  };

  return (
    <div className="bg-themeCore min-h-screen h-full">
      {isVisible && (
        <AddJobGradeDefination
          isVisible={isVisible}
          setIsVisible={onCloseModal}
        />
      )}

      <div className="appTitle text-base font-[500] mx-5">
        Grade Definitions
      </div>
      <div className="jobCreationBar  sticky top-0 z-10 bg-white px-14 py-6 rounded-lg flex justify-between items-center my-7">
        <div className="title text-themeBlue font-[500] text-3xl">
          Job Grade Definitions
        </div>
        <CustomButton
          onClick={() => {
            dispatch(resetJobDetails);
            setIsVisible(true);
          }}
          btnName="Create Grade Definitions"
          customClasses="shadow-lg"
        />
      </div>

      <div className="jobCreationListing bg-white px-14 py-6 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />
        <Table
          columns={columns}
          data={gradePointsForCompany}
          isLoading={isLoadingGradePointsForCompanies}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </div>
    </div>
  );
};

export default JobGradeDefination;
