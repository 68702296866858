// import React from 'react';

// interface JobFunction {
//     name: string;
// }

// interface JobFunctionBtnProps {
//     jobFunction: JobFunction;
//     onClick: () => void;
//     selected?: JobFunction | null; // Added optional selected prop
// }

// const JobFunctionBtn: React.FC<JobFunctionBtnProps> = ({ jobFunction, onClick, selected }) => {
//     return (
//         <div
      
//             onClick={onClick}
//             className={`jobFunctionBtn truncate hover:bg-[#00519D] hover:text-white transition-all delay-0 text-[#00519D] shadow-[#c6c8ff] shadow-lg rounded-lg w-full text-center text-sm font-[500] py-3 px-2 border-[#efeeff] border cursor-pointer ${selected?.name === jobFunction.name ? "!bg-[#00519D] !text-white" : ""}`}
//         >
//             {jobFunction.name}
//         </div>
//     );
// };

// export default JobFunctionBtn;


import React,{memo} from 'react';

interface JobFunction {
    name: string;
}

interface JobFunctionBtnProps {
    jobFunction: JobFunction;
    onClick: () => void;
    selected?: JobFunction | null;
}

const JobFunctionBtn: React.FC<JobFunctionBtnProps> = ({ jobFunction, onClick, selected }) => {
    return (
        <div
            title={jobFunction.name} // Tooltip added here
            onClick={onClick}
            className={`jobFunctionBtn truncate hover:bg-[#00519D] hover:text-white transition-all delay-0 text-[#00519D] shadow-[#c6c8ff] shadow-lg rounded-lg w-full text-center text-sm font-[500] py-3 px-2 border-[#efeeff] border cursor-pointer ${selected?.name === jobFunction.name ? "!bg-[#00519D] !text-white" : ""}`}
        >
            {jobFunction.name}
        </div>
    );
};

export default memo(JobFunctionBtn);

