import React from "react";
import { useTypedSelector } from "@/hooks";
import { useFetchJobEvaluationByIdQuery } from "@/services";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { JobEvaluationInquiryStatus } from "@/constants";
import { paths } from "@/router";
import { Spin } from "antd";
import "./JobEvaluationDataProvider.less";

type Props = {
  children: JSX.Element;
};

const JobEvaluationDataProvider = ({ children }: Props) => {
  const { user } = useTypedSelector(state => state.auth);
  const { pathname } = useLocation<{ pathname: string }>();
  const history = useHistory();

  const { id: jobDetails_id, status } = useTypedSelector(
    state => state?.jobEvaluation
  );
  const jobId = jobDetails_id ? jobDetails_id.toString() : "";

  const company_id = user?.company?.id || 0;

  const { evaluationId: jobIdInParameter } =
    useParams<{ evaluationId: string }>();

  const {
    data: jobEvaluationByIdData,
    isLoading: isLoadingJobEvaluationById,
    isFetching,
    isSuccess,
  } = useFetchJobEvaluationByIdQuery(
    {
      company_id,
      job_evaluation_id: jobIdInParameter,
    },
    { refetchOnMountOrArgChange: true }

    // { skip: is_create }
  );

  if (isLoadingJobEvaluationById && isFetching) {
    return (
      <div className="example">
        <Spin />
      </div>
    );
  }

  if (
    (status?.key === JobEvaluationInquiryStatus.OPEN &&
      pathname.includes("summary")) ||
    (status?.key === JobEvaluationInquiryStatus.CLOSE &&
      pathname.includes("create") &&
      jobIdInParameter === jobId &&
      isSuccess)
  ) {
    history.push(paths.client.jobEvaluation.listing);
    return (
      <div className="example">
        <Spin />
      </div>
    );
  }

  return children;
};

export default JobEvaluationDataProvider;
