import { Button, Modal as AntdModal } from "antd";
import CheckLogo from "@assets/images/check.png";
import ClientCheckLogo from "@assets/images/client-check.png";
import smiley from "../pages/client/JobEvaluation/CreateJobEvaluation/smiley.png";
import "./style.css"

export const showSuccessPopup = ({
  title,
  desc,
  onClick,
  role = "admin",
}: {
  title: string;
  desc: string;
  role?: "admin" | "client";
  onClick?: () => void;
}) => {
  const handleClick = () => {
    AntdModal.destroyAll();
    onClick && onClick();
  };

  const parts = desc.split("Job Grade :");
  AntdModal.info({
    className: 'bg-transparent',
    style: { background: "transparent" },

    icon: null,


    okButtonProps: { style: { display: "none" } },
    bodyStyle: { textAlign: "center", backgroundColor: "transparent !important" },
    content: (

      <div className="fixed z-10 inset-0 overflow-y-auto ">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div className="inline-block align-bottom shadow-[#33dd33] bg-white rounded-lg text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            {/* Remove the transparent background from these divs */}
            <div className="bg h-40 absolute -z-40 -top-10 -left-2 transform -rotate-6 bg-[#EEEEEE] w-full"></div>
            <div className="bg h-[90px] -z-40 absolute top-0 left-0  bg-[#EEEEEE] w-full"></div>
            <div className="smileyBg z-50 mt-20">
              <div className="smiley w-40 mx-auto">
                <img src={smiley} className="h-full w-full" alt="smiley" />
              </div>
            </div>
            <div className="content text-center mt-10">
              <div className="title font-bold text-2xl">{title}</div>
              <div className="desc text-lg mt-5">
                {parts ?parts.reduce((acc: React.ReactNode[], part, index) => (
                  index > 0
                    ? [...acc, <strong key={index}>Job Grade :</strong>, part]
                    : [part]
                ), []):desc}
              </div>
            </div>
            <div className="cta flex justify-center mt-10 mb-5">
              <button onClick={handleClick} className="px-4 py-2 bg-[#33dd33] rounded-md font-bold text-lg text-white mx-auto">Continue</button>
            </div>
          </div>
        </div>
      </div>
    ),
  });
};
