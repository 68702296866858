import React, { ChangeEvent, FC } from "react";
import { FormControl, TextField } from "@mui/material";

interface CustomFormInputProps {
  title?: string;
  placeholder?: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  type?: "text" | "textarea";
  value: string;
  customClass?: string;
  customContainer?: string;
  name: string;
  disabled?: boolean;
}

const CustomFormInput: FC<CustomFormInputProps> = ({
  title = "",
  placeholder = "",
  onChange,
  type = "text",
  value = "",
  customClass = "",
  name,
  disabled,
  customContainer
}) => {
  return (
    <div className={`customFormInput w-full lg:w-[48%]  my-5 ${customContainer}`}>
      <h3 className="font-semibold text-themeBlue text-2xl leading-10">
        {title}
      </h3>

      {type === "textarea" ? (
        <textarea
          contentEditable={disabled}
          name={name}
          value={value}
          onChange={onChange}
          className={`w-full shadow-lg my-5 outline-none border px-3 py-3 ${customClass}`}
          placeholder={placeholder}
        />
      ) : (
        <FormControl disabled={disabled} fullWidth>
          <TextField
            contentEditable={disabled}
            name={name}
            value={value}
            onChange={onChange}
            className={`bg-white shadow-lg !rounded-none !border-none !outline-none`}
            placeholder={placeholder}
            variant="outlined"
          />
        </FormControl>
      )}
    </div>
  );
};

export default CustomFormInput;
