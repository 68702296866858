import React, { useState } from "react";
import { Button, Tabs, Modal, Upload, message, Spin } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "@/hooks";
import { showSuccessPopup } from "@/utils";
import { paths } from "@/router";
import { evaluationModelOptions } from "@/constants";
import {
  ICreateJobForEvaluation,
  ErrorServices,
  useCreateJobForEvaluationMutation,
  useFetchAllBusinessUnitsQuery,
  useFetchAllJobFunctionsQuery,
  useFetchAllKnowledgeAndSkillsQuery,
  useUpdateJobEvaluationMutation,
  useCreateJobEvaluationMutation,
  useFetchAllRevenueResponsibilityQuery,
  useFetchAllNonFinancialResponsibilityQuery,
  useFetchAllImpactOfDecisionMakingQuery,
  useFetchAllRoleImpactOnOrganisationQuery,
  useFetchAllComplexitiesQuery,
  useFetchAllNatureOfCommunicationQuery,
  useFetchAllProblemSolvingAndSolutioningQuery,
  useFetchAllApplicationsQuery,
} from "@services";
import {
  IJobFunctionItem,
  IModal,
  ISubJobFunctionItem,
  IBusinessUnitItem,
  ICountry,
  IRegion,
} from "@types";
import { showSuccessPopup1 } from "@/utils/showSuccessPopup1";
const { TabPane } = Tabs;

interface MoreSubOptionsRef {
  display_name: string;
  key: number;
  id: number;
}

interface SubOption {
  display_name: string;
  key: number;
  more_sub_options_ref: MoreSubOptionsRef[];
}

interface Option {
  display_name: string;
  key: number;
  sub_options?: SubOption[];
  id?: number;
}

interface TopLevelOption {
  display_name: string;
  key: number;
  options: Option[];
}

interface ImpactData {
  [key: string]: TopLevelOption;
}

type FieldMap = {
  [key: string]: string;
};

interface JobCreationModalProps {
  visible: boolean;
  onClose: () => void;
}

const JobCreationModal: React.FC<JobCreationModalProps> = ({
  visible,
  onClose,
}) => {
  const [fileData, setFileData] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [totalJobs, setTotalJobs] = useState(0);
  const [completedJobs, setCompletedJobs] = useState(0);
  const [missingErrors, setMissingErrors] = useState<any[]>([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;

  const { data: jobFunctionsData } = useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: businessUnitsData } = useFetchAllBusinessUnitsQuery({
    company_id,
  });
  const { data: businessUnits } = businessUnitsData || {};

  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );

  const {
    evaluationInformation,
    jobDetails,
    knowledgeSkillAndApplication,
    problemSolvingSolutioning,
    stakeHolderManagement,
    decisionMakingImpact,
    financialResponsibility,
    id: jobDetails_id,
    status,
    je_form,
    organization_chart,
  } = useTypedSelector(state => state?.jobEvaluation);
  const {
    jobTitle,
    job_function,
    sub_job_function,
    evaluation_model,
    region,
    business_unit,
    country_id,
  } = jobDetails;

  const {
    knowledge_and_skill,
    application,
    minimum_level_education,
    minimum_level_education_reason,
    educational_degree,
  } = knowledgeSkillAndApplication;
  const { problem_solving_solutioning } = problemSolvingSolutioning;
  const { communication_nature, complexity } = stakeHolderManagement;
  const { role_impact_on_organisation, impact_of_decision } =
    decisionMakingImpact;
  const { non_financial_responsibility, revenue_responsibility } =
    financialResponsibility;
  const [createJobForEvaluation] = useCreateJobForEvaluationMutation();
  const {
    data: knowledgeAndSkillsData,
    isLoading: isLoadingknowledgeAndSkillsData,
  } = useFetchAllKnowledgeAndSkillsQuery(null);
  const { data: knowledgeAndSkillOptions } = knowledgeAndSkillsData || {
    data: [],
  };

  const { data: applicationsData, isLoading: isLoadingApplicationsData } =
    useFetchAllApplicationsQuery(null);
  const { data: applicationOptions } = applicationsData || { data: [] };

  const {
    data: problemSolvingAndSolutioningData,
    isLoading: isLoadingProblemSolvingAndSolutioningData,
  } = useFetchAllProblemSolvingAndSolutioningQuery(null);
  const { data: problemSolvingAndSolutioningOptions } =
    problemSolvingAndSolutioningData || {
      data: [],
    };

  const {
    data: natureOfCommunicationData,
    isLoading: isLoadingNatureOfCommunicationData,
  } = useFetchAllNatureOfCommunicationQuery(null);
  const { data: natureOfCommunicationOptions } = natureOfCommunicationData || {
    data: [],
  };

  const { data: complexityData, isLoading: isLoadingComplexityData } =
    useFetchAllComplexitiesQuery(null);
  const { data: complexityOptions } = complexityData || {
    data: [],
  };

  const {
    data: roleImpactOnOrganisationData,
    isLoading: isLoadingRoleImpactOnOrganisationData,
  } = useFetchAllRoleImpactOnOrganisationQuery(null);
  const { data: roleImpactOnOrganisationOptions } =
    roleImpactOnOrganisationData || {
      data: [],
    };

  const {
    data: impactOfDecisionMakingData,
    isLoading: isLoadingImpactOfDecisionMakingData,
  } = useFetchAllImpactOfDecisionMakingQuery(null);
  const { data: impactOfDecisionMakingOptions } =
    impactOfDecisionMakingData || {
      data: [],
    };

  const {
    data: nonFinancialResponsibilityData,
    isLoading: isLoadingNonFinancialResponsibilityData,
  } = useFetchAllNonFinancialResponsibilityQuery(null);
  const { data: nonFinancialResponsibilityOptions } =
    nonFinancialResponsibilityData || {
      data: [],
    };

  const {
    data: revenueResponsibilityData,
    isLoading: isLoadingRevenueResponsibility,
  } = useFetchAllRevenueResponsibilityQuery(null);
  const { data: revenueResponsibilityOptions } = revenueResponsibilityData || {
    data: [],
  };

  const job_evaluation_id = jobDetails_id || 0;

  const [updateJobEvaluation, { isLoading: isLoadingUpdateJobEvaluation }] =
    useUpdateJobEvaluationMutation();
  const [createJobEvaluation, { isLoading: isLoadingCreateJobEvaluation }] =
    useCreateJobEvaluationMutation();

  const handleFileUpload = (file: any) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setFileData(jsonData);
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent auto upload
  };

  const impactData: ImpactData = {
    "Leadership Role": {
      display_name: "Leadership Role",
      key: 0,
      options: [
        {
          display_name: "Function Head",
          key: 1,
          sub_options: [
            {
              display_name: "Single Business Unit",
              key: 1,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 1, id: 3 },
                { display_name: "Regional", key: 2, id: 5 },
                { display_name: "Global", key: 3, id: 7 },
              ],
            },
            {
              display_name: "Multiple Business Units",
              key: 2,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 4, id: 5 },
                { display_name: "Regional", key: 5, id: 4 },
                { display_name: "Global", key: 6, id: 6 },
              ],
            },
          ],
        },
        {
          display_name: "Business Head",
          key: 2,
          sub_options: [
            {
              display_name: "Single Business Unit",
              key: 11,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 1, id: 4 },
                { display_name: "Regional", key: 2, id: 6 },
                { display_name: "Global", key: 3, id: 8 },
              ],
            },
            {
              display_name: "Multiple Business Units",
              key: 12,
              more_sub_options_ref: [
                { display_name: "Local or Single Country", key: 4, id: 6 },
                { display_name: "Regional", key: 5, id: 8 },
                { display_name: "Global", key: 6, id: 8 },
              ],
            },
          ],
        },
      ],
    },
    "Non Leadership Role": {
      display_name: "Non Leadership Role",
      key: 1,
      options: [
        {
          display_name: "Team",
          key: 0,
          id: 0, // Include id here
        },
        {
          display_name: "Department",
          key: 1,
          id: 1, // Include id here
        },
        {
          display_name: "Corporate or Global",
          key: 2,
          id: 2, // Include id here
        },
      ],
    },
  };
  const excelToBackendFieldMap: FieldMap = {
    "Department Name": "department_name",
    "Business Unit": "business_unit",
    Region: "region",
    Country: "country",
    "Evaluation Model": "evaluation_model",
    "No Of Direct Reports": "no_direct_report",
    "Job Title": "job_title",
    "Job Function": "job_function",
    "Job Sub Function": "sub_job_function",
    "Knowledge and Skills": "knowledge_skills",
    "Knowledge and Skills Application": "knowledge_skills_application",
    "Area of specialization/degree": "area_of_specializations_or_degree",
    "Problem Solving And Solutioning": "problem_solving_solutioning",
    "Nature of Communication": "nature_of_communication",
    Complexity: "complexity",
    "Role Impact on Organization": "role_impact_on_organization_test",
    "Role Impact on Organization - Level": "role_impact_on_organization_test1",
    "Role Impact on Organization - Business Unit": "role_impact_on_organization_test2",
    "Role Impact on Organization - Extent": "role_impact_on_organization",
    "Impact of Decision Making": "impact_of_decision_making",
    "Revenue Responsibility": "revenue_responsibility",
    "Non-Financial Responsibility": "non_financial_responsibility",
  };

  const isFieldValid = (field: any) =>
    field !== null && field !== undefined && field !== "";

  const normalizeString = (str?: any) => {
    // Check if the input is a number, and convert to string if necessary
    if (typeof str === "number") {
      str = str.toString();
    }

    // Check if it's a string before calling toLowerCase, return empty string otherwise
    if (typeof str === "string") {
      return str.toLowerCase().trim();
    }

    // If the input is not a string or number, return an empty string
    return "";
  };

  const validateFields = (
    row: any,
    requiredFields: string[],
    index: number
  ) => {
    const missingFields = requiredFields.filter(
      field => !isFieldValid(row[field])
    );

    return missingFields.length > 0
      ? `Missing required field ${missingFields.join(", ")} at row ${
          index + 1
        }: `
      : "";
  };

  const getDynamicFieldMap = (row: any) => {
    let dynamicFieldMap = { ...excelToBackendFieldMap };

    if (
      normalizeString(row["Role Impact on Organization"]) ==
      "non leadership role"
    ) {
      // Remove Role Impact on Organization - Business Unit and Head for Non Leadership Roles
      delete dynamicFieldMap["Role Impact on Organization - Business Unit"];
      delete dynamicFieldMap["Role Impact on Organization - Level"];
    }
    if (normalizeString(row["Evaluation Model"]) === "financial") {
      delete dynamicFieldMap["Non Financial Rsponsibility"];
    } else if (
      normalizeString(row["Evaluation Model"]) === "non financial" ||
      normalizeString(row["Evaluation Model"]) === "non-financial"
    ) {
      delete dynamicFieldMap["Revenue Responsibility"];
    }

    return dynamicFieldMap;
  };

  const validateImpactData = (
    row: any,
    index: number,
    errors: string[]
  ): any => {
    const roleImpactOnOrg = normalizeString(
      row?.role_impact_on_organization_test
    );
    const roleImpactOnOrgHead = normalizeString(
      row?.role_impact_on_organization_test1
    );
    const roleImpactOnOrgUnit = normalizeString(
      row?.role_impact_on_organization_test2
    );
    const roleImpactOnOrgItem = normalizeString(
      row?.role_impact_on_organization
    );

    // Step 1: Find the top-level category ("Role Impact on Organization")

    const topLevelCategory = Object.keys(impactData).find(
      category =>
        normalizeString(impactData[category].display_name) == roleImpactOnOrg
    );

    if (!topLevelCategory) {
      errors.push(`Invalid Role Impact on Organization at row ${index + 1}`);
      return null;
    }

    const topLevelOption = impactData[topLevelCategory];
    let lastSelectedOption: any = null;

    if (roleImpactOnOrg === normalizeString("Leadership Role")) {
      // Step 2: Validate "Role Impact on Organization - Level"
      const selectedTopLevelOption = topLevelOption.options.find(
        option => normalizeString(option.display_name) === roleImpactOnOrgHead
      );

      if (!selectedTopLevelOption) {
        errors.push(
          `Invalid Role Impact on Organization - Level at row ${index + 1}`
        );
        return null;
      }

      lastSelectedOption = selectedTopLevelOption;

      // Step 3: Validate "Role Impact on Organization - Business Unit" if needed
      const selectedSubOption = selectedTopLevelOption.sub_options?.find(
        subOption =>
          normalizeString(subOption.display_name) === roleImpactOnOrgUnit
      );

      if (!selectedSubOption) {
        errors.push(
          `Invalid Role Impact on Organization - Business Unit at row ${index + 1}`
        );
        return null;
      }

      lastSelectedOption = selectedSubOption;


      // Step 4: Validate "Role Impact on Organization - Extent" against "more_sub_options_ref"
      const validUnit = selectedSubOption.more_sub_options_ref.find(
        unit => normalizeString(unit.display_name) === roleImpactOnOrgItem
      );


      if (!validUnit) {
        errors.push(
          `Invalid Role Impact on Organization - Extent at row ${index + 1}`
        );
        return null;
      }

      lastSelectedOption = validUnit;
    } else if (roleImpactOnOrg === normalizeString("Non Leadership Role")) {
      // For "Non Leadership Role", validate "Role Impact on Organization - Extent" against top-level options
      const validOption = topLevelOption.options.find(
        option => normalizeString(option.display_name) === roleImpactOnOrgItem
      );

      if (!validOption) {
        errors.push(
          `Invalid Role Impact on Organization - Extent at row ${index + 1}`
        );
        return null;
      }

      lastSelectedOption = validOption;
    }

    return lastSelectedOption;
  };

  const transformString = (str: string) => {
    return str
      .replace(/[,&]/g, "") // Remove commas and & symbols
      .replace(/-/g, " ") // Replace hyphens with spaces
      .split(" ") // Split the string into words
      .map(word => {
        if (word.includes("+")) {
          return word.charAt(0).toUpperCase() + word.slice(1); // If the word contains '+', leave it as is
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize first letter
      })
      .join(" ") // Join words back into a single string
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .replace(/\s+\+/g, "+"); // Remove any space before the + symbol
  };

  // Example Usages

  const validateExcelData1 = async (rows: any[]) => {
    const errors: string[] = [];
    const validData: any[] = [];

    rows.forEach((row, index) => {
      const mappedRow: any = {};
      const dynamicFieldMap = getDynamicFieldMap(row); // Get the dynamic field map for the current row
      // Map fields using the dynamic field map
      for (const [excelField, backendField] of Object.entries(
        dynamicFieldMap
      )) {
        mappedRow[backendField] = row[excelField];
      }

      // Check if all required fields are valid
      const requiredFields = Object.values(dynamicFieldMap);
      const fieldError = validateFields(mappedRow, requiredFields, index);
      if (fieldError) {
        errors.push(fieldError);
        return;
      }

      setMissingErrors(errors);
      // Destructure fields from mappedRow
      const {
        department_name,
        business_unit,
        region,
        country,
        evaluation_model,
        job_function,
        sub_job_function,
        knowledge_skills,
        knowledge_skills_application,
        educational_degree,
        problem_solving_solutioning,
        nature_of_communication,
        complexity,
        role_impact_on_organization,
        impact_of_decision_making,
        revenue_responsibility,
        non_financial_responsibility,
      } = mappedRow;


      // Normalize values based on evaluation_model
      const normalizedValues = {
        department_name: normalizeString(department_name),
        business_unit: normalizeString(business_unit),
        region: normalizeString(region),
        country: normalizeString(country),
        evaluation_model: normalizeString(evaluation_model),
        job_function: normalizeString(job_function),
        sub_job_function: normalizeString(sub_job_function),
        knowledge_skills: normalizeString(knowledge_skills),
        knowledge_skills_application: normalizeString(
          knowledge_skills_application
        ),
        educational_degree: normalizeString(educational_degree),
        problem_solving_solutioning: normalizeString(
          problem_solving_solutioning
        ),
        nature_of_communication: normalizeString(nature_of_communication),
        complexity: normalizeString(complexity),
        impact_of_decision_making: normalizeString(impact_of_decision_making),
        ...(normalizeString(evaluation_model) === "financial"
          ? { revenue_responsibility: normalizeString(revenue_responsibility) }
          : {}),

        ...(normalizeString(evaluation_model) === "non financial"
          ? {
              non_financial_responsibility: normalizeString(
                non_financial_responsibility
              ),
            }
          : {}),

        minimum_level_of_education_required: "-",
        why_is_this_level_of_education_required: "-",
      };

      if (normalizedValues.evaluation_model === "non financial") {
        normalizedValues.evaluation_model = "non-financial";
      }

      // Further processing of normalizedValues...

      const validBusinessUnit =
        businessUnits.find(
          (unit: any) =>
            normalizeString(unit.name) === normalizedValues.business_unit
        ) || null; // Fallback to null if not found

      if (!validBusinessUnit) {
        errors.push(`Invalid Business Unit at row ${index + 1}`);
        return;
      }
      mappedRow.business_unit = validBusinessUnit.id;

      const validRegion = validBusinessUnit.regions?.find(
        (r: any) => normalizeString(r.name) === normalizedValues.region
      );

      if (!validRegion) {
        errors.push(`Invalid Region for Business Unit at row ${index + 1}`);
        return;
      }
      mappedRow.region = validRegion.id;

      const validCountry = validRegion.countries?.find(
        (c: any) => normalizeString(c.name) === normalizedValues.country
      );

      if (!validCountry) {
        errors.push(`Invalid Country for Region at row ${index + 1}`);
        return;
      }
      mappedRow.country = validCountry.id;

      // Validate Evaluation Model
      const validEvaluationModel = evaluationModelOptions.find(
        (model: any) =>
          normalizeString(model.display_name) ===
          normalizedValues.evaluation_model
      );

      if (!validEvaluationModel) {
        errors.push(`Invalid Evaluation Model at row ${index + 1}`);
        return;
      }
      mappedRow.evaluation_model = validEvaluationModel.key;

      // Validate Job Function and Sub-Job Function
      const selectedJobFunction = jobFunctions.find(
        (func: any) =>
          normalizeString(func.name) === normalizedValues.job_function
      );

      if (!selectedJobFunction) {
        errors.push(`Invalid Job Function at row ${index + 1}`);
        return;
      }
      mappedRow.job_function = selectedJobFunction.id;

      const validSubJobFunction = selectedJobFunction.job_sub_functions?.find(
        (subFunc: any) =>
          normalizeString(subFunc.name) === normalizedValues.sub_job_function
      );

      if (!validSubJobFunction) {
        errors.push(
          `Invalid Job Sub-Function for selected Job Function at row ${
            index + 1
          }`
        );
        return;
      }
      mappedRow.sub_job_function = validSubJobFunction.id;

      // 2nd api data

      let knowledgeAndSkillNewOptions = [
        ...knowledgeAndSkillOptions.slice(0, 3),
        {
          display_name: "Multi Specialization",
          key: "multiple_specialization",
        },
        ...knowledgeAndSkillOptions.slice(3),
      ];

      let knowledge_skillsd = transformString(
        normalizedValues.knowledge_skills
      );



      if(knowledge_skillsd === "Basic Or Elementary"){
        knowledge_skillsd = "Basic or Elementary"
      }

      const knowledge_skills_selected = knowledgeAndSkillNewOptions.find(
        (func: any) => func.display_name == knowledge_skillsd
      );

      console.log(knowledge_skills_selected,'Test')

      if (!knowledge_skills_selected) {
        errors.push(`Invalid Knowledge and Skills at row ${index + 1}`);
        return;
      }
      mappedRow.knowledge_skills = knowledge_skills_selected.key;

      const knowledge_skillsd_app = transformString(
        normalizedValues.knowledge_skills_application
      );

      const knowledge_skills_application_selected = applicationOptions.find(
        (func: any) => func.display_name === knowledge_skillsd_app
      );

      if (!knowledge_skills_application_selected) {
        errors.push(
          `Invalid Knowledge and Skills for Application at row ${index + 1}`
        );
        return;
      }

      mappedRow.knowledge_skills_application =
        knowledge_skills_application_selected.key;

      const problem_Solving = transformString(
        normalizedValues.problem_solving_solutioning
      );

      // Validate Problem Solving and Solutioning Options
      const problem_solving_solutioning_selected =
        problemSolvingAndSolutioningOptions.find(
          (option: any) => option.display_name == problem_Solving
        );

      if (!problem_solving_solutioning_selected) {
        errors.push(
          `Invalid Problem Solving and Solutioning option at row ${index + 1}`
        );
        return;
      }

      mappedRow.problem_solving_solutioning =
        problem_solving_solutioning_selected.key;

      // Validate Nature of Communication

      const nature_of_communication1 = transformString(
        normalizedValues.nature_of_communication
      );
      const nature_of_communication_selected =
        natureOfCommunicationOptions.find(
          (option: any) => option.display_name === nature_of_communication1
        );

      if (!nature_of_communication_selected) {
        errors.push(`Invalid Nature of Communication at row ${index + 1}`);
        return;
      }

      mappedRow.nature_of_communication = nature_of_communication_selected.key;

      // Validate Complexity Options

      const complexity1 = transformString(normalizedValues.complexity);
      const complexity_selected = complexityOptions.find(
        (option: any) => option.display_name === complexity1
      );

      if (!complexity_selected) {
        errors.push(`Invalid Complexity at row ${index + 1}`);
        return;
      }

      mappedRow.complexity = complexity_selected.key;

      let impactData = validateImpactData(mappedRow, index, errors);
      let finalItem = impactData
        ? roleImpactOnOrganisationOptions[impactData.id]
        : null;

      mappedRow.role_impact_on_organization = finalItem && finalItem.key;

      const result = transformString(
        normalizedValues.impact_of_decision_making
      );
      // Validate Impact of Decision Making
      const impact_of_decision_making_selected =
        impactOfDecisionMakingOptions.find(
          (option: any) => option.display_name === result
        );

      if (!impact_of_decision_making_selected) {
        errors.push(`Invalid Impact of Decision Making at row ${index + 1}`);
        return;
      }

      mappedRow.impact_of_decision_making =
        impact_of_decision_making_selected.key;

      if (normalizedValues.revenue_responsibility) {
        const revenue_responsibility_selected =
          revenueResponsibilityOptions.find(
            (option: any) =>
              normalizeString(option.display_name) ===
              normalizedValues.revenue_responsibility
          );

        if (!revenue_responsibility_selected) {
          errors.push(`Invalid Revenue Responsibility at row ${index + 1}`);
          return;
        }

        mappedRow.revenue_responsibility = revenue_responsibility_selected.key;
      }

      // Validate Non-Financial Responsibility (for non-financial models)
      if (normalizedValues.non_financial_responsibility) {
        const non_financial_responsibility_selected =
          nonFinancialResponsibilityOptions.find(
            (option: any) =>
              normalizeString(option.display_name) ===
              normalizedValues.non_financial_responsibility
          );

        if (!non_financial_responsibility_selected) {
          errors.push(
            `Invalid Non-Financial Responsibility at row ${index + 1}`
          );
          return;
        }

        mappedRow.non_financial_responsibility =
          non_financial_responsibility_selected.key;
      }

      mappedRow.minimum_level_of_education_required =
        normalizedValues.minimum_level_of_education_required;
      mappedRow.why_is_this_level_of_education_required =
        normalizedValues.why_is_this_level_of_education_required;

      delete mappedRow.role_impact_on_organisation_test;
      delete mappedRow.role_impact_on_organisation_test1;
      delete mappedRow.role_impact_on_organisation_test2;
      validData.push(mappedRow);
    });

    if (errors.length > 0) {
      // message.error(`Validation Errors: ${errors.join(", ")}`);
      return;
    }

    setTotalJobs(validData.length);
    setCompletedJobs(0);
    setLoadingMessage(`Starting job creation process...`);

    setUploading(true); // Set uploading state to true
    let jobResults = []; // Array to hold job creation and evaluation results
    try {
      setUploading(true); // Set uploading state to true

      for (let i = 0; i < validData.length; i++) {
        const body = validData[i];


        // First API call: Create a job for evaluation
        const createJobResponse = await createJobForEvaluation({
          company_id,
          body,
        }).unwrap();

        const job_evaluation_id = createJobResponse?.data?.id;

        if (job_evaluation_id) {
          console.log(job_evaluation_id, "Job evaluation ID");

          const data = {
            job_title: body.job_title || undefined,
            department_name: body.department_name || undefined,
            evaluation_model: body.evaluation_model || undefined,
            no_direct_report: body.no_direct_report.toString() || undefined,
            business_unit: body?.business_unit,
            country: body?.country,
            region: body?.region,
            knowledge_skills: body?.knowledge_skills || undefined,
            knowledge_skills_application:
              body?.knowledge_skills_application || undefined,
            minimum_level_of_education_required:
              body.minimum_level_of_education_required || undefined,
            why_is_this_level_of_education_required:
              body.why_is_this_level_of_education_required || undefined,
            area_of_specializations_or_degree:
              body.area_of_specializations_or_degree || undefined,
            problem_solving_solutioning:
              body.problem_solving_solutioning || undefined,
            nature_of_communication: body.nature_of_communication || undefined,
            complexity: body.complexity || undefined,
            role_impact_on_organization:
              body.role_impact_on_organization || undefined,
            impact_of_decision_making:
              body.impact_of_decision_making || undefined,
            non_financial_responsibility:
              body.non_financial_responsibility || undefined,
            revenue_responsibility: body.revenue_responsibility || undefined,
          };

          // Second API call: Use the job_evaluation_id in the job evaluation
          const jobEvaluationResponse = await createJobEvaluation({
            company_id,
            job_evaluation_id,
            body: data,
          }).unwrap();

          const evaluationResult =
            jobEvaluationResponse?.data?.evaluation_result;

          // Track the result of this job
          jobResults.push({
            job_title: body.job_title,
            job_evaluation_id: job_evaluation_id,
            evaluationResult: evaluationResult,
          });

          // Update progress after each job is successfully created
          setCompletedJobs(i + 1);
          setLoadingMessage(`Creating job ${i + 1} of ${validData.length}...`);
        } else {
          throw new Error(`Job evaluation ID not found for job ${i + 1}`);
        }
      }

      // After all jobs are processed
      setFileData([]); // Reset file data state
      // Consolidate the results into a success message
      // let successMessage = `You have successfully created and evaluated the following ${validData.length} jobs:\n`;

      // // Construct the message with proper formatting
      // jobResults.forEach((result, index) => {
      //   successMessage += `\n${index + 1}. Job Title: ${
      //     result.job_title
      //   }\n   Evaluation ID: ${result.job_evaluation_id}\n   Grade: ${
      //     result.evaluationResult
      //   }`;
      // });

      // Set the loading message
      setLoadingMessage("All jobs created successfully!");

      // Show the success popup with the formatted message
      showSuccessPopup1({
        title: "New Jobs Created",
        desc: "You have successfully created and evaluated the jobs!",
      });
    } catch (error) {
      ErrorServices(error);
      setUploading(false);
      setLoadingMessage("An error occurred during job creation.");
      console.error("Error during job creation:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleDownload = () => {
    // Define the URL of the file
    const fileUrl = "job_details.csv"; // Update this path to match the location of your file

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "jobs.csv"; // You can set the filename here

    // Append the anchor to the body
    document.body.appendChild(link);

    // Programmatically click the anchor to trigger the download
    link.click();

    // Clean up by removing the anchor element
    document.body.removeChild(link);
  };

  return (
    <>
      {visible && (
        <Modal
          title="Upload Files"
          visible={visible}
          onCancel={onClose}
          footer={null}
          width={800}
          className="bg-white"
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Upload" key="1">
              <div className="flex justify-end ">
                <Button
                  type="default"
                  onClick={handleDownload}
                  className="mt-4"
                  icon={<DownloadOutlined />} // Add the download icon
                >
                  Download Sample CSV File
                </Button>
              </div>

              <br />
              <Upload
                customRequest={({ file, onSuccess, onError }: any) => {
                  try {
                    handleFileUpload(file);
                    onSuccess(null, file);
                  } catch (error) {
                    onError(error);
                  }
                }}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Upload Excel File</Button>
              </Upload>
              <Button
                type="primary"
                onClick={() => validateExcelData1(fileData)}
                loading={uploading}
                disabled={fileData.length === 0}
                className="mt-4"
              >
                Submit
              </Button>

             <div className="flex justify-start items-center mt-4">
             <span className="text-sm text-red-500 font-medium">
                {missingErrors.map((error, index) => (
                  <span key={index}>
                  {index+1}.{" "}  {error}
                    <br />
                  </span>
                ))}
              </span>
             </div>

              <div className="flex justify-center items-center mt-4">
                {uploading && <Spin tip={loadingMessage} />}
              </div>
            </TabPane>

            <TabPane tab="Recent" key="2">
              <div className="text-center">No recent files</div>
            </TabPane>
          </Tabs>
        </Modal>
      )}
    </>
  );
};

export default JobCreationModal;
