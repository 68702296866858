export const roles = {
  admin: ["TOM_MASTER_USER", "TOM_SUPER_USER", "TOM_ADMIN", "TOM_SALES"],
  company: [
    "COMPANY_SUPER_USER", // apni compani
    "COMPANY_ADMIN",
    "COMPANY_USER",
    "COMPANY_BUSINESS_ACCESS",
  ],
};

export enum UserRoles {
  COMPANY_SUPER_USER = "COMPANY_SUPER_USER",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  COMPANY_USER = "COMPANY_USER",
  COMPANY_BUSINESS_ACCESS = "COMPANY_BUSINESS_ACCESS",
  TOM_MASTER_USER = "TOM_MASTER_USER",
}

export interface IRoleManement {
  id: UserRoles;
  applicationCheck: boolean;
  permissionsCheck: boolean;
}

// export const roleManagement = <IRoleManement[]>[
//   {
//     id: UserRoles.COMPANY_SUPER_USER,
//     applicationCheck: true,
//     permissionsCheck: false,
//   },
//   {
//     id: UserRoles.COMPANY_ADMIN,
//     applicationCheck: true,
//     permissionsCheckk: false,
//   },
//   {
//     id: UserRoles.TOM_MASTER_USER,
//     applicationCheck: false,
//     permissionsCheck: false,
//   },
//   {
//     id: UserRoles.COMPANY_USER,
//     applicationCheck: true,
//     permissionsCheck: true,
//   },
//   {
//     id: UserRoles.COMPANY_BUSINESS_ACCESS,
//     applicationCheck: true,
//     permissionsCheck: false,
//   },
// ];
