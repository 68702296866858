import { useState, useRef} from "react";
import { Col, Row, TableColumnsType, Tag, Button, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./jobEvaluation.less";
import { paths } from "@router";
import { useFetchAllJobEvaluationsQuery } from "@services";
import Table from "@components/Table";
import AddJob from "./AddJob";
import { IJobEvaluationDataResponse } from "@/store/jobEvaluation/types";
import { dateFormat, JobEvaluationInquiryStatus } from "@/constants";
import moment, { isMoment } from "moment";
import { useTypedSelector } from "@/hooks";
import { handlePageChange, resetJobDetails } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";
import CustomButton from "@/components/customButton";
import SearchBox from "@/components/searchInput";
import JobCreationModal from "@/components/JobCreationModal";
import JobCreationTableComponent from "@components/jobCreationTable";
import UploadMassJobs from "@components/UploadMassJobs";
import { getTokenFromSharedStorage } from "@/services/cookies";


const Jobs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const [isMassUpload, setIsMassUpload] = useState(false);
  const { jobEvaluationListingPage } = useTypedSelector(
    state => state?.jobEvaluation
  );

  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;

  const { data: jobEvaluationsData, isLoading } =
    useFetchAllJobEvaluationsQuery({
      company_id,
      page: jobEvaluationListingPage,
    });
  const { data, pagination } = jobEvaluationsData || {};


  // khurram's work
  const [filteredData, setFilteredData] = useState()
  const [searchInput, setSearchInput] = useState("");

  // const searchJobs = async (searchTerm: string): Promise<void> => {
  //   const token = getTokenFromSharedStorage()
  //   // console.log("token",token)
  //   try {
  //     const response = await fetch(
  //       `https://api-py2.the-talent-accelerator.com/api/company/${company_id}/job-evaluations/search?search=${searchTerm}`,
  //       // `${process.env.REACT_APP_BASE_URL}/company/${company_id}/job-evaluations/search?search=${searchTerm}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Authorization": `Bearer ${token.access}`, // Ensure you have a valid token here
  //           "Content-Type": "application/json", // Set content type
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       // Handle non-200 responses
  //       const errorData = await response.json();
  //       console.error("Search API error:", errorData.message);
  //       return; // Exit the function if the response is not OK
  //     }

  //     const data = await response.json(); // Parse the JSON from the response
  //     console.log("data:", data)
  //     if (data) {
  //       setFilteredData(data.results); // Adjust according to the actual response structure
  //       console.log("data", data.results)
  //     } else {
  //       console.error("Search API error:", data);
  //     }
  //   } catch (error) {
  //     // Handle generic error
  //     if (error instanceof Error) {
  //       console.error("Error:", error.message);
  //     } else {
  //       console.error("Unexpected error:", error);
  //     }
  //   }
  // };


  // function searchJobs(dataArg: any, searchTerm: any) {
  //   const result = dataArg.filter((item: any) =>
  //     item.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     item.job_function.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     item.country.name.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   console.log("first", result)
  //   setfilteredData(result);
  //   return result
  // }



  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const searchJobs = async (searchTerm: string): Promise<void> => {
  
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current); // Clear the previous timeout if it exists
    }
  
    debounceTimeout.current = setTimeout(async () => {
      const token = getTokenFromSharedStorage();
      try {
        const response = await fetch(
          `https://api-py2.the-talent-accelerator.com/api/company/${company_id}/job-evaluations/search?search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token.access}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error("Search API error:", errorData.message);
          return;
        }
  
        const data = await response.json();
        if (data) {
          setFilteredData(data.results); // Adjust according to the actual response structure
          console.log("data", data.results);
        } else {
          console.error("Search API error:", data);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error:", error.message);
        } else {
          console.error("Unexpected error:", error);
        }
      }
    }, 300); // Adjust the debounce delay as necessary
  };
  


  const columns: TableColumnsType<IJobEvaluationDataResponse> = [
    {
      title: "Evaluation Id",
      defaultSortOrder: "ascend",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a?.id - b?.id,
      render: (_, record, index) => record?.id,
    },
    {
      title: "DATE",
      key: "updated_at",
      dataIndex: "created_at",
      sorter: (a: IJobEvaluationDataResponse, b: IJobEvaluationDataResponse) => {
        return moment(a.updated_at).unix() - moment(b.updated_at).unix();
      },
      render: (record: any) => {

        return <div className="text-wrap">
          {`${moment(record).format("DD-MMM-YYYY")}`}
        </div>
      },
    }
    ,
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      sorter: (a: IJobEvaluationDataResponse, b: IJobEvaluationDataResponse) => {
        return (a.job_title || "").localeCompare(b.job_title || "");
      },
    },
    {
      title: "Job Function",
      key: "job_function",
      dataIndex: ["job_function", "name"],
      sorter: (a: IJobEvaluationDataResponse, b: IJobEvaluationDataResponse) => {
        const jobFunctionA = a.job_function?.name || "";
        const jobFunctionB = b.job_function?.name || "";
        return jobFunctionA.localeCompare(jobFunctionB);
      },
    },
    {
      title: "Country",
      key: "country",
      dataIndex: ["country", "name"],
      sorter: (a: IJobEvaluationDataResponse, b: IJobEvaluationDataResponse) => {
        const jobFunctionA = a.country?.name || "";
        const jobFunctionB = b.country?.name || "";
        return jobFunctionA.localeCompare(jobFunctionB);
      },
    },
    {
      title: "Score",
      key: "evaluation_result",
      width: 150,
      align: "center",
      sorter: (a: IJobEvaluationDataResponse, b: IJobEvaluationDataResponse) => {
        const scoreA = a.evaluation_result || 0;
        const scoreB = b.evaluation_result || 0;
        return scoreA - scoreB;
      },
      render: (record: IJobEvaluationDataResponse) => {
        const score = record.evaluation_result ? record.evaluation_result : "-";
        return <div className="text-wrap">{score}</div>;
      },
    },

    // {
    //   title: "Job Evaluation Status",
    //   // dataIndex: "status",
    //   key: "status",
    //   align: "center",
    //   render: (record: IJobEvaluationDataResponse) => {
    //     const color: { [key: string]: string } = {
    //       open: "green",
    //       "in progress": "gold",
    //       close: "red",
    //     };
    //     const key = record.status?.key!;
    //     return <Tag color={color[key.toLowerCase()]}>{key.toUpperCase()}</Tag>;
    //   },
    // },
  ];

  const onRowClick = (data: IJobEvaluationDataResponse) => {
    const jobId = data.id ? data.id.toString() : "";
    data.status?.key === JobEvaluationInquiryStatus.CLOSE
      ? history.push(paths.client.jobEvaluation.summary(jobId))
      : history.push(paths.client.jobEvaluation.create(jobId));
  };
  const onCloseModal = () => {
    setIsVisible(false);
  };
  const onCloseMassModal = () => {
    setIsMassUpload(false);
  };
  const handleTablePageChange = (newPage: number) => {
    dispatch(handlePageChange(newPage));
  };

  return (
    <div className="bg-themeCore min-h-screen h-full">
      <div className="appTitle text-base font-[500] mx-5">Job Creation</div>
      <div className="jobCreationBar sticky top-0 z-10 bg-white px-14 py-6 rounded-lg flex justify-between items-center my-7">
        <div className="title text-themeBlue font-[500] text-3xl">
          Job Creation
        </div>

        <div className="flex items-center">

          <CustomButton
            onClick={() => {
              dispatch(resetJobDetails);
              setIsVisible(true);
            }}
            btnName="Create Individual Job"
            customClasses="shadow-lg"
          />

          <CustomButton
            onClick={() => {
              dispatch(resetJobDetails);
              setIsMassUpload(true);
            }}
            btnName="Create Mass Upload"
            customClasses="shadow-lg ml-6"
          />


        </div>
      </div>
      <div className="jobCreationListing bg-white px-14 py-6 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          // onChange={value => setSearchInput(value)}
          onChange={value => { setSearchInput(value); searchJobs(value) }} // khurram's work
        />
        <JobCreationTableComponent
          onRowClick={onRowClick}
          columns={columns}
          data={filteredData || data} // khurram's work
          isLoading={isLoading}
          pagination={true}
          count={pagination?.count}
          onChangePage={handleTablePageChange}
          page={jobEvaluationListingPage}
        />

        <JobCreationModal visible={isVisible} onClose={onCloseModal} />
        <UploadMassJobs visible={isMassUpload} onClose={onCloseMassModal} />
      </div>
    </div>
  );
};

export default Jobs;
