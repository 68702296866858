import { ReactComponent as UsersIcon } from "@assets/images/users.svg";
import MenuIcon from "@assets/images/menuIcon.png";
import { ReactComponent as CompaniesIcon } from "@assets/images/companies.svg";
import { paths, permissions } from "@router";
import { IJobGrade } from "@/types";
import { FaBuilding, FaUsers } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";

import { FaKey } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";

import {
  checkPermission,
  checkRoleBasedAuthorization,
  getCompanyId,
} from "@/utils";
import { VIEW_JOB_EVALUATION } from "@/router/permissions";
import { UserRoles } from "@/constants";

const CustomIconWrapper = () => <FaBuilding size={18} />;
const CustomUsersIconWrapper = () => <FaUsers size={18} />;
const CustomDashboardIconWrapper = () => <MdDashboard size={18} />;
const IconWrapper = () => (
  <div className="border-2 p-1 rounded-md">
    <FaKey size={18} />
  </div>
);
const IconWrapper2 = () => (
  <div className="border-2 p-1 rounded-md">
    <IoCubeOutline className="font-bold" size={20} />
  </div>
);

const { client } = paths;

export interface IConfig {
  title: string;
  icon: any;
  path: string | Array<string>;
  routingPath?: string;
  sub?: Array<IConfig>;
  permission?: string;
  client?: boolean;
  disabled?: boolean;
  fallbackMessage?: string;
}

export const admin_config: Array<IConfig> = [];

export const client_config = (jobGrades: IJobGrade[]): Array<IConfig> => [
  {
    title: "Job Tool",
    icon: UsersIcon,
    client: true,
    path: [
      client.jobEvaluation.listing,
      client.jobEvaluation.create(),
      client.jobEvaluation.summary(),
      client.jobGradeDefination.listing,
      client.jobLibrary.listing,
      client.payNet.listing,
      client.payNetJobFunctions.listing,
     
    ],

    sub: checkRoleBasedAuthorization([UserRoles.TOM_MASTER_USER])
      ? [
        {
          title: "Grade Definition",
          icon: IconWrapper,
          path: client.jobGradeDefination.listing,
          permission: permissions.VIEW_OFFER,
        },
        {
          title: "Job Evaluation",
          icon: IconWrapper2,
          path: client.jobEvaluation.listing,
          permission: permissions.VIEW_JOB_EVALUATION,
        },
        {
          title: "Job Library",
          icon: IconWrapper2,
          path: client.jobLibrary.listing,
          permission: permissions.VIEW_JOB_LIBRARY,
        },
        {
          title: "Pay Net",
          icon: IconWrapper2,
          path: client.payNet.listing,
          permission: permissions.VIEW_PAY_NET,
        },
        {
          title: "Custom Job Library",
          icon: IconWrapper2,
          path: client.payNetJobFunctions.listing,
          permission: permissions.VIEW_PAY_NET_JOB_FUNCTIONS,
        },
       
      ]
      : checkRoleBasedAuthorization([UserRoles.COMPANY_SUPER_USER])
        ? [
          {
            title: "Job Evaluation",
            icon: CompaniesIcon,
            path: client.jobEvaluation.listing,
            permission: permissions.VIEW_JOB_EVALUATION,
          },
        ]
        : [],
  },
  // ...(!checkRoleBasedAuthorization([
  //   UserRoles.COMPANY_SUPER_USER,
  //   UserRoles.TOM_MASTER_USER,
  // ])
  //   ? []
  //   : [
  //       {
  //         title: "Users",
  //         icon: UsersIcon,
  //         path: [
  //           client.company.profile.details(),
  //           paths.client.company.profile.businessUnits.listing(),
  //           paths.client.company.profile.regions(),
  //         ],
  //         sub: [
  //           {
  //             title: "Sub-Admins",
  //             icon: ClientSubAdminsIcon,
  //             path: client.sub_admins.listing,
  //             permission: permissions.VIEW_COMPANY_USER,
  //           },
  //           {
  //             title: "Company",
  //             icon: CompaniesIcon,
  //             path: client.company.profile.details(`${getCompanyId()}`),
  //           },
  //         ],
  //       },
  //     ]),
];
