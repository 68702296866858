import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiMethodes, endpoints } from "@/constants";
import { tomService } from "./restService";

export interface IJobFunctionDescription {
    name: string;
    description: string;
    is_active: string;
}

export interface ISubJobFunctionDescription {
    custom_job_function_id: number;
    name: string;
    description: string;
}

export const payNetApi = createApi({
    reducerPath: "payNetApi",
    baseQuery: tomService({
        // baseUrl: `${process.env.REACT_APP_BASE_URL}`,
        baseUrl: `https://api-py2.the-talent-accelerator.com/api`,
    }),
    tagTypes: ["PayNetJobFunctions"],
    endpoints: (builder) => ({
        /**
         * Fetch all job functions
         */
        fetchPayNetJobFunctions: builder.query<any[], any>({
            query: (company_id) => ({ url: `/company/${company_id}/custom-jobs/all/`, method: ApiMethodes.GET }),

            providesTags: ["PayNetJobFunctions"],

        }),
        /**
         * Create a new job function
         */
        createPayNetJobFunction: builder.mutation<void, { payload: IJobFunctionDescription; company_id: number }>({
            query: ({ payload, company_id }) => ({
                url: `/company/${company_id}/custom-jobs/create/`,
                // url: endpoints.PAYNET.CREATE_JOB_FUNCTION,
                method: ApiMethodes.POST,
                body: payload,

            }),
            invalidatesTags: ["PayNetJobFunctions"],
        }),
        /**
         * Create a new job-sub function
         */
        createPayNetJobSubFunction: builder.mutation<void, ISubJobFunctionDescription>({
            query: (payload) => ({

                // url: "/test",
                url: endpoints.PAYNET.CREATE_JOB_SUB_FUNCTION,
                method: ApiMethodes.POST,
                body: payload,
            }),
            invalidatesTags: ["PayNetJobFunctions"],
        }),
        /**
         * Create a new job-sub function
         */
        uploadPayNetJobSubFunctionDoc: builder.mutation<void, any>({
            query: (payload) => {
                for (const [key, value] of payload.entries()) {
                    console.log(`${key}:`, value);
                }
                return {
                    // url: "/test",
                    url: endpoints.PAYNET.UPLOAD_JOB_SUB_FUNCTION_DOC,
                    method: ApiMethodes.POST,
                    body: payload,
                    formData: true,
                }
            },
            invalidatesTags: ["PayNetJobFunctions"],
        }),
        /**
         * Update an existing job function by name
         */
        // updatePayNetJobFunction: builder.mutation<
        //     void,
        //     { name: string; updatedData: Partial<IJobFunction> }
        // >({
        //     query: ({ name, updatedData }) => ({
        //         url: endpoints.PAYNET.UPDATE_JOB_FUNCTION,
        //         method: ApiMethodes.PUT,
        //         body: updatedData,
        //     }),
        //     invalidatesTags: ["PayNetJobFunctions"],
        // }),
        /**
         * Delete a specific job function by name
         */
        // deletePayNetJobFunction: builder.mutation<void, string>({
        //     query: (name) => ({
        //         url: endpoints.PAYNET.DELETE_JOB_FUNCTION,
        //         method: ApiMethodes.DELETE,
        //     }),
        //     invalidatesTags: ["PayNetJobFunctions"],
        // }),
    }),
});



export const {
    useFetchPayNetJobFunctionsQuery,
    useCreatePayNetJobFunctionMutation,
    useCreatePayNetJobSubFunctionMutation,
    useUploadPayNetJobSubFunctionDocMutation
    // useUpdatePayNetJobFunctionMutation,
    // useDeletePayNetJobFunctionMutation
} = payNetApi

