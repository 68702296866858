import React, { useEffect, useState } from "react";
import { Space, Table, Skeleton, Modal, Empty, Pagination } from "antd";
import CustomButton from "@components/customButton";
import EvaluationStatusProgressBar from "@components/EvaluationStatusProgressBar";
import { useDispatch } from "react-redux";
// import JobStatusModal from "@components/JobStatusModal";
import { IJobEvaluationDataResponse } from "@/store/jobEvaluation/types";


interface SetStatusButtonProps {
  record: any; // Define the type for the record prop
  onClick: any;
}

const SetStatusButton: React.FC<SetStatusButtonProps> = ({
  record,
  onClick,
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleSwitchModal = () => {
    setShowModal(!showModal);
    onClick(record);
  };

  const key = record.status?.key!;

  return (
    <>
      <CustomButton
        onClick={handleSwitchModal}
        btnName={
          key.toUpperCase() === "OPEN" ? "Set Status" : "Completed"
          // key.toUpperCase() === "OPEN" ? "Set Status" : key.toUpperCase()+"D"
        }
        customClasses={`shadow-lg ${key.toUpperCase() === "CLOSE" && "!px-4"} `}
      />
      {/* <JobStatusModal
        record={record}
        visible={showModal}
        onClose={handleModalClose}
      /> */}
    </>
  );
};

interface JobCreationTableProps {
  data: any[];
  columns: any[];
  onRowClick?: (record: any) => any | void;
  locale?: any;
  isLoading?: boolean;
  page?: number;
  pagination?: boolean;
  onChangePage?: any;
  count?: number;
  scroll?: number;
}

function calculateFilledPercentage(data: any) {
  let total = Object.keys(data).length;
  let filled = Object.values(data).filter(
    val => val !== undefined && val !== null && val !== ""
  ).length;

  let perce = (filled / total) * 100;

  return perce.toFixed(2);
}

const JobCreationTableComponent: React.FC<JobCreationTableProps> = ({
  columns,
  data,
  onRowClick,
  pagination = false,
  isLoading,
  page = 1,
  count = 10,
  onChangePage,
  locale,
  scroll,
}) => {
  return (
    <>
      <Table
        // onRow={(record: any) => ({
        //   onClick: () => onRowClick && onRowClick(record),
        // })}
        // pagination={{ position: ["bottomCenter"] }}

        pagination={false}
        columns={[
          ...columns,
          {
            title: "Job Evaluation Status",
            key: "status",
            // khurram's work
            sorter: (a: IJobEvaluationDataResponse, b: IJobEvaluationDataResponse) => {
              const percentageA = calculateFilledPercentage(a);
              const percentageB = calculateFilledPercentage(b);
              return Number(percentageA) - Number(percentageB); // Sort in ascending order
            },
            render: (record: any) => (
              <Space size="middle">
                <SetStatusButton record={record} onClick={onRowClick} />
                <EvaluationStatusProgressBar record={record} />
              </Space>
            ),
          },
        ]}
        dataSource={isLoading ? [] : data}
        rowKey="id"
        locale={
          !data?.length && !isLoading
            ? locale
            : {
                emptyText: isLoading ? (
                  <>
                    {[1, 2, 3, 4].map(x => (
                      <Skeleton.Input key={x} size="small" active={true} />
                    ))}
                  </>
                ) : (
                  <Empty />
                ),
              }
        }
      />

      {data?.length && pagination ? (
        <div className="table__pagination flex justify-center">
          <Pagination
            onChange={onChangePage}
            current={page}
            defaultCurrent={1}
            total={count}

          />
        </div>
      ) : null}
    </>
  );
};

export default JobCreationTableComponent;
