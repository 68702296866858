import React, { FC, ChangeEvent } from "react";
import { CgSearch } from "react-icons/cg";

interface SearchBoxProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

const SearchBox: FC<SearchBoxProps> = ({
  placeholder = "Search",
  value,
  onChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="my-6 flex justify-end">
      <div className="SearchBox flex gap-1 items-center bg-themeCore w-fit py-1 border px-2 rounded-xl">
        <CgSearch className="text-xl text-gray-500" />
        <input
          type="text"
          className="outline-none bg-transparent px-2 py-1 text-sm font-medium"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default SearchBox;
