import React from "react";
import { Progress } from "antd";
import "./style.css";

interface EvaluationStatusProgressBarProps {
  record: any;
}

const EvaluationStatusProgressBar: React.FC<EvaluationStatusProgressBarProps> =
  ({ record }) => {
    let data: any = {
      job_title: record.job_title || undefined,
      department_name: record.department_name || undefined,
      evaluation_model: record.evaluation_model?.key || undefined,
      no_direct_report: record.no_direct_report || undefined,
      business_unit: record?.business_unit,
      country: record?.country,
      job_function: record?.job_function,
      sub_job_function: record?.sub_job_function,
      region: record?.region,
      knowledge_skills: record.knowledge_skills?.key || undefined,
      knowledge_skills_application:
        record.knowledge_skills_application?.key || undefined,
      minimum_level_of_education_required:
        record.minimum_level_of_education_required || undefined,
      why_is_this_level_of_education_required:
        record.why_is_this_level_of_education_required || undefined,
      area_of_specializations_or_degree:
        record.area_of_specializations_or_degree || undefined,
      problem_solving_solutioning:
        record.problem_solving_solutioning?.key || undefined,
      nature_of_communication: record.nature_of_communication?.key || undefined,
      complexity: record.complexity?.key || undefined,
      role_impact_on_organization:
        record.role_impact_on_organization?.key || undefined,
      impact_of_decision_making:
        record.impact_of_decision_making?.key || undefined,
    };

    let evaluationModelKey = record.evaluation_model?.key || undefined;

    const key = record.status?.key!;

    if (evaluationModelKey === "financial") {
      data.revenue_responsibility =
        record.revenue_responsibility?.key || undefined;
    } else {
      data.non_financial_responsibility =
        record.non_financial_responsibility?.key || undefined;
    }

    function calculateFilledPercentage(data: any) {
      let total = Object.keys(data).length;
      let filled = Object.values(data).filter(
        val => val !== undefined && val !== null && val !== ""
      ).length;

      let perce = (filled / total) * 100;

      return perce.toFixed(2);
    }

    let percentageFilled = calculateFilledPercentage(data);

    return (
      <div className="">
        <div className="progrress">
          <Progress
            type={"circle"}
            percent={
              key === "open"
                ? parseInt(percentageFilled) - 1
                : parseInt(percentageFilled)
            }
            // style={{width:"10px !important", height:"10px !important"}}
            width={55}
            strokeWidth={12}
            // #00AC4F green
            strokeColor={
              parseInt(percentageFilled) - 1 > 99 ? "#00AC4F" : "#00519D"
            }
            className={`${
              parseInt(percentageFilled) - 1 > 99 ? "green-text" : "blue-text"
            }`}
          />
        </div>
      </div>
    );
  };

export default EvaluationStatusProgressBar;
