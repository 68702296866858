import { Col, Row, Button, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import moment from "moment";

import "./jobEvaluationSummary.less";
import { useTypedSelector } from "@hooks";

import { paths } from "@router";
import { dateFormat } from "@/constants";
import JobEvaluationDataProvider from "../JobEvaluationDataProvider/JobEvaluationDataProvider";
import { DownloadOutlined } from "@ant-design/icons";
import { useFetchAllBusinessUnitsQuery } from "@services";
import CustomButton from "@/components/customButton";

const JobEvaluationSummary = () => {
  const history = useHistory();

  const {
    jobDetails,
    knowledgeSkillAndApplication,
    problemSolvingSolutioning,
    stakeHolderManagement,
    decisionMakingImpact,
    financialResponsibility,
    updated_at,
    evaluation_result,
    je_form,
    organization_chart,
  } = useTypedSelector(state => state?.jobEvaluation);

  const {
    jobTitle,
    job_function,
    sub_job_function,
    country_id,
    business_unit,
    region,
  } = jobDetails;
  const {
    knowledge_and_skill,
    application,
    minimum_level_education,
    minimum_level_education_reason,
    educational_degree,
  } = knowledgeSkillAndApplication;
  const { problem_solving_solutioning } = problemSolvingSolutioning;
  const { communication_nature, complexity } = stakeHolderManagement;
  const { role_impact_on_organisation, impact_of_decision } =
    decisionMakingImpact;
  const { non_financial_responsibility, revenue_responsibility } =
    financialResponsibility;

  const { user } = useTypedSelector(state => state.auth);

  const company_id = user?.company?.id || 0;

  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};

  return (
    <JobEvaluationDataProvider>
      <div className="container">
        <div className="addCompany">
          <div className="addCompany__content">
        <Row>
          <Col span={24}>
            <div className=" mb-2">{`Evaluation Summary`}</div>
            <div className="main-heading mb-16 !text-[#00519D]">{`Job Evaluation Details Summary`}</div>
          </Col>
        </Row>
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
             
                title={"1 - Job Evaluation Summary"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Evaluation Date">
                  {moment(updated_at).format(dateFormat)}
                </Descriptions.Item>
                <Descriptions.Item label="Evaluation Model">
                  {jobDetails.evaluation_model?.display_name}
                </Descriptions.Item>
                <Descriptions.Item label="Evaluation Result">
                  {evaluation_result!}
                </Descriptions.Item>
                {je_form && je_form.length && (
                  <Descriptions.Item label="Job Evaluation Form">
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={() => window.open(`${je_form}`, "_blank")}
                    >
                      Download
                    </Button>
                  </Descriptions.Item>
                )}
                {organization_chart && organization_chart.length && (
                  <Descriptions.Item label="Organizational Chart">
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        window.open(`${organization_chart}`, "_blank")
                      }
                    >
                      Download
                    </Button>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Row>
          </div>

          <div className="addCompany__content">
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
                title={"2 - Job Details"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Job Title">
                  {jobTitle}
                </Descriptions.Item>
                <Descriptions.Item label="Job Function">
                  {job_function?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Job Sub-Function">
                  {sub_job_function?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Business Unit">
                  {
                    businessUnits?.find((opt: any) => opt.id === business_unit)
                      ?.name
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Region">
                  {
                    businessUnits
                      ?.filter((elm: any) => elm.id === business_unit)[0]
                      ?.regions?.find((opt: any) => opt.id === region)?.name
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {
                    businessUnits
                      ?.filter((elm: any) => elm.id === business_unit)[0]
                      ?.regions?.filter((elm: any) => elm.id === region)[0]
                      ?.countries?.find((opt: any) => opt.id === country_id)
                      ?.name
                  }
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </div>

          <div className="addCompany__content">
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
                title={"3 - Knowledge, Skills and Application"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Knowledge And Skills">
                  {knowledge_and_skill?.display_name}
                </Descriptions.Item>
                <Descriptions.Item label="Application">
                  {application?.display_name}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Minimum level of education required">
                  {minimum_level_education}
                </Descriptions.Item> */}
                {/* <Descriptions.Item label="Why is this level of education required">
                  {minimum_level_education_reason}
                </Descriptions.Item> */}
                <Descriptions.Item label="Area of specialization/degree">
                  {educational_degree}
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </div>

          <div className="addCompany__content">
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
                title={"4 - Problem Solving And Solutioning"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Problem Solving And Solutioning">
                  {problem_solving_solutioning?.display_name}
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </div>

          <div className="addCompany__content">
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
                title={"5 - StakeHolder Management"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Nature of communication">
                  {communication_nature?.display_name}
                </Descriptions.Item>
                <Descriptions.Item label="Complexity">
                  {complexity?.display_name}
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </div>

          <div className="addCompany__content">
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
                title={"6 - Decision Making And Impact"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Role Impact On Organisation">
                  {role_impact_on_organisation?.display_name}
                </Descriptions.Item>
                <Descriptions.Item label="Impact Of Decision Making">
                  {impact_of_decision?.display_name}
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </div>

          <div className="addCompany__content">
            <Row className="addCompany__row" justify="space-between">
              <Descriptions
                title={"7 - Financial Responsibility"}
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Non Financial Responsibility">
                  {non_financial_responsibility?.display_name}
                </Descriptions.Item>
                <Descriptions.Item label="Scope of Accountability">
                  {revenue_responsibility?.display_name}
                </Descriptions.Item>
              </Descriptions>
            </Row>

            <Row className="addCompany__btns">
              <Col span={24}>
                {/* <Button
                  type="primary"
                  size="large"
                  onClick={() =>
                    history.push(paths.client.jobEvaluation.listing)
                  }
                >
                  Back
                </Button> */}
                <CustomButton
                  onClick={() =>
                    history.push(paths.client.jobEvaluation.listing)
                  }
                 btnName="Back"/>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </JobEvaluationDataProvider>
  );
};

export default JobEvaluationSummary;
