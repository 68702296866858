import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { payNetApi } from "@/services";

export interface IPayNetSubJobFunction {
  name: string;
  docs: Record<string, string[]>;
}

export interface IPayNetJobFunction {
  name: string;
  subFunction: IPayNetSubJobFunction[];
}

export interface IPayNetJobFunctionsState {
  jobFunctions: IPayNetJobFunction[];
  loading: boolean;
  error: string | null;
}

const initialState: any = {
  jobFunctions: [],
  loading: false,
  error: null,
};

const payNetSlice = createSlice({
  name: "payNet",
  initialState,
  reducers: {
    // Save job functions
    savePayNetJobFunctions: (state, action: PayloadAction<any[]>) => {
      state.jobFunctions = action.payload;
    },

    // Add a new job function
    // addPayNetJobFunction: (state, action: PayloadAction<IPayNetJobFunction>) => {
    //   state.jobFunctions.push(action.payload);
    // },

    // Reset all job functions
    // resetPayNetJobFunctions: state => {
    //   state.jobFunctions = [];
    // },

    // Remove a specific job function by name
    // removePayNetJobFunction: (state, action: PayloadAction<string>) => {
    //   state.jobFunctions = state.jobFunctions.filter(
    //     jobFunction => jobFunction.name !== action.payload
    //   );
    // },

    // Update a specific job function by name
    // updatePayNetJobFunction: (
    //   state,
    //   action: PayloadAction<{ name: string; updatedData: Partial<IPayNetJobFunction> }>
    // ) => {
    //   const { name, updatedData } = action.payload;
    //   const index = state.jobFunctions.findIndex(jobFunction => jobFunction.name === name);
    //   if (index !== -1) {
    //     state.jobFunctions[index] = {
    //       ...state.jobFunctions[index],
    //       ...updatedData,
    //     };
    //   }
    // },
  },
  extraReducers: builder => {
    // console.log('builder')
    builder.addMatcher(
      payNetApi.endpoints.fetchPayNetJobFunctions.matchPending,
      (state) => {
        // console.log("pending")
        state.loading = true;
        state.error = null; // Clear any previous errors
      }
    );
    builder.addMatcher(
      payNetApi.endpoints.fetchPayNetJobFunctions.matchFulfilled,
      (state, {payload} ) => {
        // console.log("hey",state,payload)
        state.jobFunctions = payload;
        state.loading = false;
      }
    );
    builder.addMatcher(
      payNetApi.endpoints.fetchPayNetJobFunctions.matchRejected,
      (state, { error }) => {
        console.log("error",error)
        state.loading = false;
        state.error = error.message || "An error occurred while fetching job functions.";
      }
    );
  },
});

export const {
  savePayNetJobFunctions,
  // addPayNetJobFunction,
  // resetPayNetJobFunctions,
  // removePayNetJobFunction,
  // updatePayNetJobFunction,
} = payNetSlice.actions;

export default payNetSlice.reducer;
