import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  authApi,
  companiesApi,
  jobFunctionsApi,
  jobEvaluationApi,
  businessUnitApi,
  sectorsApi,
  companySubAdmins,
  subAdminApi,
  gradeApi,
  gradeSetupApi,
  longTermIPApi,
  payNetApi
} from "@services";
import { authReducer } from "./auth";
import { countriesReducer } from "./countries";
import {
  ISectorsState,
  ISubAdminReducer,
  IAuthState,
  IBusinessUnitState,
} from "@types";
import { jobEvaluationReducer } from "./jobEvaluation";
import { jobGradeMappingApi } from "@/services/jobGradeMapping";
import { businessUnitReducer } from "./business-unit";
import { sectorsReducer } from "./sectors";
import { subAdminReducer } from "./sub-admin";
import { gradeReducer } from "./grade";
import { IPayNetJobFunctionsState, paynetReducer } from "./pay-net";

export interface ICombineReducerProps {
  auth: IAuthState;
  businessUnit: IBusinessUnitState;
  subAdmin: ISubAdminReducer;
  sectors: ISectorsState;
  paynet:IPayNetJobFunctionsState
}

const appReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [jobFunctionsApi.reducerPath]: jobFunctionsApi.reducer,
  [jobEvaluationApi.reducerPath]: jobEvaluationApi.reducer,
  [jobGradeMappingApi.reducerPath]: jobGradeMappingApi.reducer,
  [businessUnitApi.reducerPath]: businessUnitApi.reducer,
  [sectorsApi.reducerPath]: sectorsApi.reducer,
  [companySubAdmins.reducerPath]: companySubAdmins.reducer,
  [gradeApi.reducerPath]: gradeApi.reducer,
  [gradeSetupApi.reducerPath]: gradeSetupApi.reducer,
  [subAdminApi.reducerPath]: subAdminApi.reducer,
  [longTermIPApi.reducerPath]: longTermIPApi.reducer,
  [payNetApi.reducerPath]: payNetApi.reducer,
  auth: authReducer,
  countries: countriesReducer,
  jobEvaluation: jobEvaluationReducer,
  businessUnit: businessUnitReducer,
  sectors: sectorsReducer,
  grade: gradeReducer,
  subAdmin: subAdminReducer,
  paynet:paynetReducer
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth","paynet"],
};

const rootReducer = (state: any, action: any) => {
  if (
    action?.meta?.arg?.endpointName === "logout" &&
    action?.type === "authApi /executeMutation/fulfilled"
  ) {
    state = {} as RootState;
    localStorage.clear();
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools:
    process.env.NODE_ENV !== "production" && typeof window !== "undefined",
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    authApi.middleware,
    companiesApi.middleware,
    jobFunctionsApi.middleware,
    jobEvaluationApi.middleware,
    jobGradeMappingApi.middleware,
    gradeApi.middleware,
    gradeSetupApi.middleware,
    sectorsApi.middleware,
    companySubAdmins.middleware,
    longTermIPApi.middleware,
    payNetApi.middleware
  ],
});

window.store = store;
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
