import React from "react";

interface CustomButtonProps {
  btnName?: string;
  onClick?: () => void;
  customClasses?: string;
  disabledProp?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  btnName = "Custom Button",
  onClick,
  customClasses = "",
  disabledProp,
}) => {
  return (
    <button
      disabled={disabledProp}
      onClick={onClick}
      className={`block bg-themeBlue text-white py-2 px-5 rounded-md ${customClasses} ${
        disabledProp ? "!bg-gray-400" : ""
      }`}
    >
      {btnName}
    </button>
  );
};

export default CustomButton;
