


import { useState, useCallback, useEffect } from "react";
import "./PayNet.less";
import SearchBox from "@/components/searchInput";
import JobFunctionBtn from "./JobFunctionBtn";
import { GrDownload } from "react-icons/gr";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import CustomButton from "@/components/customButton";
import { useCreatePayNetJobFunctionMutation, useCreatePayNetJobSubFunctionMutation, useFetchPayNetJobFunctionsQuery } from "@/services";
import Modal from "@/components/Modal";
import CustomPayNetModal from "@/components/CustomPaynetModal";
import FileUploadWithLevel from "@/components/FileUpload";
import { useTypedSelector } from "@/hooks";


interface FileUrl {
  [level: string]: string[];
}

interface SubJobFunction {
  id: number;
  name: string;
  file_url: FileUrl;
}

export interface JobFunction {
  id: number;
  name: string;
  custom_job_sub_functions: SubJobFunction[];
}

interface ApiResponse {
  success: boolean;
  is_validation_error: boolean;
  message: string;
  code: number;
  data: JobFunction[];
  error?: Record<string, any>;
  is_paginated: boolean;
  pagination: Record<string, any>;
  isLoading?: boolean;
}



const PayNetJobFunctions = () => {

  const { user } = useTypedSelector(state => state.auth);
  const { id: company_id } = user?.company || {};
  console.log("company_id",company_id)
 
  const { data, isLoading: isLoadingJobFunctions, error } = useFetchPayNetJobFunctionsQuery<ApiResponse>(company_id);

  const [createPayNetJobFunction, { isLoading: isLoadingFunction, isError: isErrorFunction, isSuccess: isSuccessFunction }] =
    useCreatePayNetJobFunctionMutation();
  const [createPayNetJobSubFunction, { isLoading: isLoadingSubFunction, isError: isErrorSubFunction, isSuccess: isSuccessSubFunction }] =
    useCreatePayNetJobSubFunctionMutation();



  const isLoading = isLoadingJobFunctions || isLoadingSubFunction || isLoadingFunction;
  // const isError = selectedJobFunc ? isErrorSubFunction : isErrorFunction;
  // const isSuccess = selectedJobFunc ? isSuccessSubFunction : isSuccessFunction;




  const [searchInput, setSearchInput] = useState("");
  const [jobFunctionsState, setJobFunctionsState] = useState<JobFunction[] | null>([]); // Initially empty array
  const [selectedJobFunc, setSelectedJobFunc] = useState<JobFunction | null>(null);
  const [selectedSubJobFunc, setSelectedSubJobFunc] = useState<SubJobFunction | null>(null);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);



  // useEffect(() => {
  //   const fetched = (data as any)?.data
  //   if (fetched) {
  //     setJobFunctionsState(fetched); // Set job functions from the fetched data
  //   }

  // }, [data]);


  // useEffect(() => {
  //   console.log("useEffect")

  //   if (selectedJobFunc) {
  //     const updatedSelectedJobFunc = jobFunctionsState?.find((jobFunction) => selectedJobFunc && selectedJobFunc.id === jobFunction.id);
  //     // const updatedSelectedJobFunc = jobFunctionsState?.filter((jobfunction)=>selectedJobFunc.id===jobfunction.id)
  //     console.log("1st Step", updatedSelectedJobFunc)
  //     if (updatedSelectedJobFunc) {

  //       setSelectedJobFunc(updatedSelectedJobFunc)
  //       console.log("2nd stp",updatedSelectedJobFunc.custom_job_sub_functions)


  //     }

  //   }

   

  // }, [jobFunctionsState])


  // useEffect(() => {

  //   console.log("useEffect for subFunction")

  //   if(selectedJobFunc){

  //     if(selectedSubJobFunc){
  //       console.log("3rd stp",selectedSubJobFunc)
        
  //       const updatedSelectedJobFunc = jobFunctionsState?.find((jobFunction) => selectedJobFunc && selectedJobFunc.id === jobFunction.id);
  //       const updatedSelectedJobSubFunc = updatedSelectedJobFunc?.custom_job_sub_functions?.find((subjobFunction) => selectedSubJobFunc && selectedSubJobFunc.id === subjobFunction.id);
  //       console.log("4th step",updatedSelectedJobSubFunc)
  //       if(updatedSelectedJobSubFunc){

  //         setSelectedSubJobFunc(updatedSelectedJobSubFunc)
  //       }
  //     }
  //   }
  // }, [selectedJobFunc])
  

  useEffect(() => {
    const fetchedData = (data as any)?.data;
    if (fetchedData) setJobFunctionsState(fetchedData);
  }, [data]);
  
  useEffect(() => {
    if (!selectedJobFunc) return;
  
    const updatedSelectedJobFunc = jobFunctionsState?.find(
      (jobFunction) => jobFunction.id === selectedJobFunc.id
    );
  
    if (updatedSelectedJobFunc) {
      setSelectedJobFunc(updatedSelectedJobFunc);
      console.log("Updated Job Function:", updatedSelectedJobFunc.custom_job_sub_functions);
    }
  }, [jobFunctionsState, selectedJobFunc]);
  
  useEffect(() => {
    if (!selectedJobFunc || !selectedSubJobFunc) return;
  
    const updatedSelectedJobFunc = jobFunctionsState?.find(
      (jobFunction) => jobFunction.id === selectedJobFunc.id
    );
  
    const updatedSelectedSubJobFunc = updatedSelectedJobFunc?.custom_job_sub_functions?.find(
      (subJobFunction) => subJobFunction.id === selectedSubJobFunc.id
    );
  
    if (updatedSelectedSubJobFunc) {
      setSelectedLevels([])
      setSelectedSubJobFunc(updatedSelectedSubJobFunc);
      console.log("Updated Sub-Job Function:", updatedSelectedSubJobFunc);
    }
  }, [selectedJobFunc, selectedSubJobFunc, jobFunctionsState]);
  


  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const level = event.target.value;
    setSelectedLevels(prevLevels =>
      prevLevels.includes(level)
        ? prevLevels.filter(l => l !== level)
        : [...prevLevels, level]
    );
  };

  const handleSelectJobFunc = (jobFunc: JobFunction) => {
    setSelectedJobFunc(jobFunc);
    setSelectedSubJobFunc(null);
  };

  const handleSelectSubJobFunc = (subJobFunc: SubJobFunction) => {
    setSelectedSubJobFunc(subJobFunc);
  };

  const handleBack = () => {
    setSelectedJobFunc(null);
    setSearchInput("");
    setJobFunctionsState(jobFunctionsState && jobFunctionsState || []); // Reset job functions
  };

  const filterJobFunctions = useCallback(
    (value: string) => {
      setSearchInput(value);

      if (selectedJobFunc?.custom_job_sub_functions) {
        if (value) {
          const filteredSubFunctions = selectedJobFunc.custom_job_sub_functions.filter(sjf =>
            sjf.name.toLowerCase().includes(value.toLowerCase())
          );
          setSelectedJobFunc(prev =>
            prev ? { ...prev, subFunction: filteredSubFunctions } : null
          );
        } else {
          const originalJobFunction = jobFunctionsState && jobFunctionsState.find(
            jf => jf.name === selectedJobFunc.name
          );
          setSelectedJobFunc(originalJobFunction || null);
        }
      }

      const filteredJobFunctions = value
        ? jobFunctionsState && jobFunctionsState.filter((jf: any) => {
          const searchValue = value.toLowerCase();
          const jobFunctionMatches = jf.name.toLowerCase().includes(searchValue);
          const subFunctionMatches = jf.custom_job_sub_functions?.some((sf: any) =>
            sf.name.toLowerCase().includes(searchValue)
          );
          return jobFunctionMatches || subFunctionMatches;
        })
        : jobFunctionsState && jobFunctionsState;

      setJobFunctionsState(filteredJobFunctions || []);
    },
    [selectedJobFunc, jobFunctionsState && jobFunctionsState]
  );

  const renderSubJobFunctions = () =>
    selectedJobFunc?.custom_job_sub_functions
      // ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((subJobFunction, index) => (
        <JobFunctionBtn
          key={index}
          onClick={() => handleSelectSubJobFunc(subJobFunction)}
          jobFunction={subJobFunction}
          selected={selectedSubJobFunc}
        />
      ));

  const renderLevels = () =>
    Object.keys(selectedSubJobFunc?.file_url || {}).map(level => (
      <label
        key={level}
        className="selectLevelCheckBox flex items-center gap-2 cursor-pointer"
      >
        <input
          type="checkbox"
          value={level}
          checked={selectedLevels.includes(level)}
          onChange={handleLevelChange}
          className="w-5 h-5 text-[#00519d] accent-[#00519d] cursor-pointer"
        />
        <span className="block w-fit text-xl font-[500]">{level}</span>
      </label>
    ));

  // const renderDocuments = () => {
  //   return selectedLevels
  //     .flatMap(
  //       level =>
  //         selectedSubJobFunc?.file_url[level].map((doc: string, index) => ({
  //           doc,
  //           level,
  //         })) || []
  //     )
  //     .map(({ doc, level }, index) => {
  //       let folderName = selectedJobFunc
  //         ? encodeURIComponent(selectedJobFunc?.name)
  //         : "";
  //       let docName = selectedSubJobFunc
  //         ? encodeURIComponent(selectedSubJobFunc?.name)
  //         : "";

  //       let encodeLevel = encodeURIComponent(level);


  //       return (
  //         <div
  //           key={index}
  //           className="docLevel flex items-center gap-2 text-themeBlue font-semibold mt-2 text-base"
  //         >
  //           <GrDownload className="text-lg font-bold" />
  //           <a
  //             href={`${doc}`}
  //             // target="_blank"
  //             rel="noopener noreferrer"
  //             download={doc?.split("/").pop()}
  //           >
  //             {level + " > " + doc && doc?.split("/")?.pop()?.toString()}
  //           </a>
  //           <FaCircleCheck className="text-green-400" />
  //         </div>
  //       );
  //     });
  // };

  const renderDocuments = () => {
    // Check if there are valid files to render
    const documents = selectedLevels
      .flatMap(
        (level) =>
          selectedSubJobFunc?.file_url[level]?.map((doc: string) => ({
            doc,
            level,
          })) || []
      );
  
    // If no documents exist, return null to render nothing
    if (!documents.length) {
      return null;
    }
  
    return documents.map(({ doc, level }, index) => {
      const folderName = selectedJobFunc
        ? encodeURIComponent(selectedJobFunc?.name)
        : "";
      const docName = selectedSubJobFunc
        ? encodeURIComponent(selectedSubJobFunc?.name)
        : "";
      const encodeLevel = encodeURIComponent(level);
  
      return (
        <div
          key={index}
          className="docLevel flex items-center gap-2 text-themeBlue font-semibold mt-2 text-base"
        >
          <GrDownload className="text-lg font-bold" />
          <a
            href={`${doc}`}
            rel="noopener noreferrer"
            download={doc?.split("/").pop()}
          >
            {level + " > " + (doc && doc?.split("/")?.pop()?.toString())}
          </a>
          <FaCircleCheck className="text-green-400" />
        </div>
      );
    });
  };
  
  const [isModalOpen, setisModalOpen] = useState(false)
  const handleCloseModal = () => {
    setisModalOpen(false)
  }
  const handleOpeneModal = () => {
    setisModalOpen(true)
  }


  if (isLoading) return <p>loading ..</p>;
  if (error) return <p>some error occured</p>;

  return (
    <div className="bg-themeCore min-h-screen h-full">
      {
        isModalOpen &&
        <CustomPayNetModal selectedJobFunc={selectedJobFunc} title={selectedJobFunc ? "Job-Sub" : "Job"} onClose={handleCloseModal} />
      }
      {
        isModalOpen &&
        <CustomPayNetModal selectedJobFunc={selectedJobFunc} title={selectedJobFunc ? "Job-Sub" : "Job"} onClose={handleCloseModal} />
      }
      <div className="jobCreationListing bg-white py-6 rounded-lg">
        <div className="jobCreationBar bg-white px-7 flex justify-between items-center my-7 sticky top-0 z-10">
          <div className="title text-themeBlue font-[600] text-3xl flex items-center gap-2">
            {selectedJobFunc && (
              <IoIosArrowBack className="cursor-pointer" onClick={handleBack} />
            )}
            <div>
              <h1 className="text-themeBlue font-[600]">CUSTOM JOB LIBRARY</h1>
              <div className="appTitle text-2xl font-[500] ">
                {selectedJobFunc
                  ? "Select your preferred Job Sub-Function:"
                  : "Select your preferred Job Function:"}
              </div>
            </div>
          </div>

          <div className="toolbar flex items-center gap-2">

            {
              selectedJobFunc ?
                <CustomButton
                  onClick={handleOpeneModal}
                  btnName="Add Sub-Job"
                  customClasses="shadow-lg"
                  disabledProp={isLoading}

                />
                :
                <CustomButton
                  onClick={handleOpeneModal}
                  btnName="Add Job"
                  customClasses="shadow-lg"
                  disabledProp={isLoading}
                  
                />
            }
            <SearchBox
              placeholder="Type to search..."
              value={searchInput}
              onChange={filterJobFunctions}
            />
          </div>
        </div>

        <div className="px-7">
          {selectedJobFunc ? (
            <>
              <div className="PayNetList grid grid-cols-4 gap-5 flex-wrap mt-11">
                {renderSubJobFunctions()}
              </div>

              {selectedSubJobFunc &&
                selectedJobFunc.custom_job_sub_functions &&
                selectedJobFunc.custom_job_sub_functions.length > 0 && (
                  <>

                    {/* file upload */}
                    <div className="fileUpload flex justify-start w-full mt-10">

                      <FileUploadWithLevel selectedSubJobFunc={selectedSubJobFunc} />
                    </div>
                    {/* file upload */}

                    <div className="appTitle text-2xl font-[500] mt-16">
                      Documents for {selectedSubJobFunc.name}:
                    </div>
                    <div className="mt-8 flex items-center gap-5">
                      {renderLevels()}
                    </div>

                    {selectedLevels.length > 0 && (
                      <>
                        <div className="appTitle text-2xl font-[500] mt-16">
                          Downloadable Sub-Functions Files:
                        </div>
                        <div className="PayNetList mt-11">
                          {renderDocuments()}
                        </div>
                      </>
                    )}
                  </>
                )}
            </>
          ) : (
            <>
              <div className="PayNetList grid grid-cols-4 gap-5 flex-wrap mt-11">
                {jobFunctionsState?.map((jobFunction, index) => (
                  <JobFunctionBtn
                    key={index}
                    onClick={() => handleSelectJobFunc(jobFunction)}
                    jobFunction={jobFunction}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayNetJobFunctions;

