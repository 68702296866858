import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, message } from "antd";

import Modal from "@components/Modal";
import { IModal } from "@types";
import { ErrorServices } from "@services";
import { useTypedSelector } from "@/hooks";
import { KeyAndDisplayName } from "@/store/jobEvaluation";
import {
  ICreateJobGradeMapping,
  useCreateJobGradeMappingMutation,
  useFetchAllJobGradeMappingCompaniesQuery,
  useFetchGradePointRangesByCompanyIdQuery,
} from "@/services/jobGradeMapping";
import CustomFormSelect from "@components/customSelect";
import CustomFormInput from "@components/customInput";
import CustomButton from "@components/customButton";

const { Option } = Select;

export type dropdown = string | undefined;

export interface IAddJob extends IModal {
  isVisible: boolean;
  setIsVisible: () => void;
}

interface IFormValues {
  company_id?: string;
  grade_point_range: KeyAndDisplayName;
  grade_point_value?: string;
}

const INITIAL_VALUES: IFormValues = {
  company_id: "",
  grade_point_range: null,
  grade_point_value: "",
};

export interface IError {
  success?: boolean;
  message?: string;
  error?: any;
  code?: number;
  data?: any;
  is_validation_error?: boolean;
}

const AddJob: React.FC<IAddJob> = ({ isVisible, setIsVisible }) => {
  const [jobGradeDefinationForm, setjobGradeDefinationForm] =
    useState(INITIAL_VALUES);

  const [disableGredeRangeDropDown, setdisableGredeRangeDropDown] =
    useState(false);

  const onChange = (event: any) => {
    setjobGradeDefinationForm(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const hasCompanyIdSected = jobGradeDefinationForm.company_id ? true : false;
  const {
    data: JobGradeMappingCompaniesData,
    isLoading: isLoadingJobGradeMappingCompaniesData,
  } = useFetchAllJobGradeMappingCompaniesQuery(null);
  const { data: jobGradeMappingCompanies } = JobGradeMappingCompaniesData || {
    data: [],
  };

  const {
    data: JobGradePointRangesData,
    isLoading: isLoadingGradePointRangesData,
    isError,
    error,
  } = useFetchGradePointRangesByCompanyIdQuery(
    {
      company_id: jobGradeDefinationForm.company_id!,
    },
    {
      skip: !hasCompanyIdSected,
    }
  );
  const { data: jobGradePointRanges } = JobGradePointRangesData || {
    data: [],
  };

  console.log(`JobGradePointRangesData - ${error} , isError : ${isError} `);
  console.log({ error });

  function getErrorDetails(error: any): error is IError {
    return "message" in error;
  }

  useEffect(() => {
    if (isError) {
      if (error && getErrorDetails(error)) {
        !error.is_validation_error && message.error(error.message);
        setdisableGredeRangeDropDown(true);
        setjobGradeDefinationForm({
          ...jobGradeDefinationForm,
          grade_point_range: null,
        });
      }
    } else {
      setdisableGredeRangeDropDown(false);
    }
  }, [error]);

  const { jobDetails } = useTypedSelector(state => state?.jobEvaluation);

  const disabled =
    !jobGradeDefinationForm.company_id ||
    !jobGradeDefinationForm.grade_point_range?.key ||
    !jobGradeDefinationForm.grade_point_value;

  const [createJobGradeMapping, { isLoading }] =
    useCreateJobGradeMappingMutation();

  const onSubmit = async () => {
    try {
      const body: ICreateJobGradeMapping = {
        grade_point_range: jobGradeDefinationForm.grade_point_range?.key!,
        grade_point_value: jobGradeDefinationForm.grade_point_value!,
        company: jobGradeDefinationForm.company_id!,
      };
      await createJobGradeMapping({
        body,
      }).unwrap();

      message.success("Job Grade Mapping Created Successfully");
      setjobGradeDefinationForm({
        ...jobGradeDefinationForm,
        grade_point_range: null,
        grade_point_value: "",
      });
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const {
    departmentName,
    country_id,
    evaluation_model,
    noOfDirectReports,
    jobTitle,
    job_function,
    sub_job_function,
  } = jobDetails;


  return (
    <>
      <div className="overflow-x-hidden transition-all delay-75  overflow-y-auto fixed mt-6 inset-0 z-50 outline-none focus:outline-none px-16">
        <div className="relative my-20 mx-auto py-10 w-9/12 self-center">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-ful bg-themeCore outline-none focus:outline-none">
            <div className="content mt-14 p-5 border">
              <div className="form2">
                <h3 className="font-semibold text-themeBlue text-3xl leading-10 text-center">
                  Define Job Grade Mapping
                </h3>
                <div className="jobDetailsInput mt-16 flex gap-5 flex-wrap">
                  <CustomFormSelect
                    disabled={false}
                    title="Company"
                    onChange={option => {
                      setjobGradeDefinationForm(prev => ({
                        ...prev,
                        company_id: option?.id?.toString(),
                      }));
                    }}
                    value={jobGradeDefinationForm.company_id || undefined}
                    placeholder="Select company from here..."
                    options={jobGradeMappingCompanies || []}
                  />

                  <CustomFormSelect
                    disabled={
                      jobGradeDefinationForm.company_id &&
                      !disableGredeRangeDropDown
                        ? false
                        : true
                    }
                    title="Grade Point Range"
                    onChange={option => {
                      setjobGradeDefinationForm(prev => ({
                        ...prev,
                        grade_point_range: {
                          key: option?.id.toString()!,
                          display_name: option?.name.toString()!,
                        },
                      }));
                    }}
                    value={
                      jobGradeDefinationForm.grade_point_range?.key || undefined
                    }
                    placeholder="Select factors from here..."
                    options={
                      jobGradePointRanges.map(
                        ({
                          key,
                          display_name,
                        }: {
                          key: string;
                          display_name: string;
                        }) => ({ id: key, name: display_name })
                      ) || []
                    }
                  />
                </div>
                <div>
                  <CustomFormInput
                    title="Grade Point Value"
                    placeholder="Enter Grade Point Value here..."
                    name={"grade_point_value"}
                    onChange={onChange}
                    value={jobGradeDefinationForm.grade_point_value || ""}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-5 justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <CustomButton
                disabledProp={disabled}
                btnName={"Add Company Grade Points Mapping"}
                onClick={onSubmit}
              />
              <CustomButton
              // customClasses="!px-14"
                btnName="Close"
                onClick={() => {
                  setIsVisible();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddJob;
