import React, { useEffect, useState } from "react";
import {
  Layout as AntdLayout,
  Breadcrumb,
  Layout,
  Menu,
  Dropdown,
  Avatar,
  message,
  Button,
} from "antd";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import Logo from "@assets/images/logoD.svg";
import LogoM from "@assets/images/logoM.svg";
import "./layout.less";
import { IoNotifications, IoApps, IoClose } from "react-icons/io5";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { client_routeConfig } from "@router";
import { ReactComponent as Down } from "@assets/images/arrow-down.svg";
import { ReactComponent as Bell } from "@assets/images/bell.svg";
import { IConfig, client_config } from "./sidebar-config";
import profilePic from "@assets/images/avatar.png";
import { useBreadcrumbs, useTypedSelector } from "@hooks";
import {
  ErrorServices,
  loadRefreshToken,
  saveTokens,
  useFetchAllCompaniesQuery,
  useFetchCompaniesQuery,
  useFetchCompanyJobGradesQuery,
  useLogoutMutation,
  useVerifyMutation,
} from "@services";
import { checkPermission, isAdmin } from "@utils";
import { appsPageUrl, ssoUrl } from "@/constants";
import { ICompany } from "@/types";

import Cookies from "universal-cookie";
import { cookiesDomain } from "@/constants";
import {
  getSelectedApplication,
  getSelectedCompanyFromStorage,
  getTokenFromSharedStorage,
} from "@/services/cookies";

const { Header, Content, Footer, Sider } = Layout;

const App = ({ children }: { children: React.ReactNode }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [currentPath, setCurrentPath] = useState<string>(
    "/client/gradedefination"
  );
  const history = useHistory();
  let location = useLocation();

  console.log({ location });
  console.log({ history });

  const { user } = useTypedSelector(state => state.auth);
  const { id: company_id } = user?.company || {};
  const { data: jobGradesData } = useFetchCompanyJobGradesQuery(
    { company_id },
    { skip: !!company_id === false }
  );
  const { data: jobGrades } = jobGradesData || {};
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [onLogout] = useLogoutMutation();
  const { pathname } = useLocation();

  const config = !isAdmin(user?.role!)
    ? client_config(jobGrades)
    : client_config(jobGrades);

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string | Array<string>,
    disabled?: boolean | undefined,
    fallbackMessage?: string | undefined,
    d?: string
  ) => {
    setCurrentPath(d || "");
    if (Array.isArray(path)) {
      event.preventDefault();
      if (disabled) message.error(fallbackMessage);
    }
  };

  const getRoute = (path: string | Array<string>): string => {
    if (typeof path == "string") return path;
    else if (
      Array.isArray(path) &&
      path.includes(pathname) &&
      typeof pathname == "string"
    )
      return pathname;
    else return "";
  };

  const breadcrumbs = useBreadcrumbs(
    !isAdmin(user?.role!) ? client_routeConfig : client_routeConfig,
    {
      disableDefaults: true,
    }
  );
  const breadcrumbs_list = breadcrumbs.map(({ breadcrumb }) => breadcrumb);
  const breadcrumbList = breadcrumbs_list?.map((breadcrumb: any) => {
    const path = breadcrumb?.key;
    const titles = breadcrumb?.props?.children?.split(" / ");
    const title = titles[titles?.length - 1];
    return <Link to={path}>{title}</Link>;
  });

  const onLogoutFromServer = async () => {
    try {
      const refresh = loadRefreshToken();
      await onLogout({ refresh }).unwrap();
      window.open(ssoUrl, "_self");
    } catch (error) {
      ErrorServices(error);
    }
  };

  const openAppsPage = () => {
    window.open(appsPageUrl, "_self");
  };

  // if (
  //   Object.values(paths.client.auth).some(path =>
  //     window.location.pathname.includes(path as string)
  //   )
  // )
  //   return <div>{children}</div>;

  const [isModalApp, setIsModalApp] = useState(false);
  const openModalApp = () => setIsModalApp(false);
  const closeModalApp = () => setIsModalApp(false);

  const menu = (
    <Menu>
      {/* <Menu.Item key="1" onClick={openModalApp}> */}
      <Menu.Item key="1" onClick={openAppsPage}>
        Apps
      </Menu.Item>
      <Menu.Item key="2" onClick={onLogoutFromServer}>
        Log out
      </Menu.Item>
    </Menu>
  );

  // select application modal starts
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
  }

  const cookies = new Cookies();

  const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={onClose}
        ></div>
        <div className="bg-white  shadow-lg p-8 z-10 min-w-[760px] rounded-lg mx-auto relative">
          <IoClose
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
            onClick={onClose}
          />
          <div className="contenr overflow-y-scroll p-5">{children}</div>
        </div>
      </div>
    );
  };
  // select application modal ends

  // sagars integtation work for modal starts
  const [
    page,
    // setPage
  ] = useState(1);
  // const selectedApp = useTypedSelector(state => state.selected_app);
  const [searchString, setSearchString] = useState<string>("");
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchAllCompaniesQuery(page);
  const { data } = companiesData || {};

  const [verifyUser, { status }] = useVerifyMutation();

  const setSelectedCompanyInStorage = (data: any) => {
    cookies.set("selected_company", data, { path: "/", domain: cookiesDomain });
  };
  let handleCompanySelect = async (app: any) => {
    setselectedCompany(app);
    setSelectedCompanyInStorage(app);

    const ssoTokens = await getTokenFromSharedStorage();
    if (ssoTokens) {
      saveTokens(ssoTokens);
      const companyId = await getSelectedCompanyFromStorage();
      await verifyUser({
        selected_application: process.env.REACT_APP_APPLICATION || "JE",
        companyId,
      });
    }

    closeModal();
    // onPressNext();
  };

  useEffect(() => {
    console.log("Fetching companies", data);
    let currentCompnay = getSelectedCompanyFromStorage();
    console.log("dsf", currentCompnay);
  }, [data]);

  // sagars integtation work for modal ends

  const getCompanyLogoUrl = () => {
    const selectedCompanyId = getSelectedCompanyFromStorage();

    const company = data.find((company: any) => {
      return company.id === selectedCompanyId && company.large_logo_url;
    });

    return company ? company.large_logo_url : null;
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        className="bg-white shadow-md"
        width={250}
      >
        <div className="demo-logo-vertical" />
        <div className="mb-6">
          <img
            src={collapsed ? LogoM : Logo}
            className=" w-full h-full px-4 py-4 cursor-pointer"
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div>
          {/* {config?.map((config: IConfig, idx) => {
            return (
              <div className="py-1 mx-1" key={idx}>
                <NavLink
                  exact={true}
                  key={idx}
                  to={getRoute(config.path)}
                  className={`group font-medium hover:no-underline visited:text-black flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-themeBlue hover:text-white  ${
                    getRoute(config.path) !== currentPath
                      ? "text-sm font-medium text-themeGrey"
                      : ""
                  } 
                ${collapsed ? "flex justify-center" : ""}
                transition-all delay-[1ms]`}
                  activeClassName="bg-themeBlue font-medium  text-white visited:text-white text-white !no-underline"
                  onClick={event => {
                    handleLinkClick(
                      event,
                      config.path,
                      config?.disabled,
                      config?.fallbackMessage,
                      getRoute(config.path)
                    );
                  }}
                >
                  <div
                    className={`${collapsed ? "" : "mr-4"} ${
                      config?.client && ""
                    }`}
                  >
                    <config.icon className="" />
                  </div>
                  <p>{!collapsed && config.title}s</p>
                </NavLink>
                {!config?.disabled &&
                  config.sub?.map((subLink: any, i: number) =>
                    checkPermission(subLink?.permission) ? (
                      <NavLink
                        key={i}
                        to={subLink.path || ""}
                        className="sider__sub__link"
                        activeClassName="sider__active"
                        onClick={event => handleLinkClick(event, subLink.path)}
                      >
                        <div
                          className={`sider__icon__container ${
                            config?.client && "sider__icon__container--client"
                          }`}
                        >
                          <subLink.icon className="sider__link__icon" />
                        </div>
                        {subLink.title}
                      </NavLink>
                    ) : null
                  )}
              </div>
            );
          })} */}

          {config?.map((config: IConfig, idx) => {
            return (
              <div className="py-1 mx-1" key={idx}>
                <NavLink
                  exact={true}
                  key={idx}
                  to={getRoute(config.path)}
                  className="sider__link"
                  activeClassName="sider__active"
                  onClick={event => {
                    handleLinkClick(
                      event,
                      config.path,
                      config?.disabled,
                      config?.fallbackMessage
                    );
                  }}
                >
                  {!collapsed && (
                    <div
                      className={`group !text-themeGrey  flex gap-1 items-center w-full h-12 px-3 mt-2 rounded `}
                    >
                      <span className="ml-2 text-sm font-medium  w-[30%]">
                        Job Tool
                      </span>

                      <div className="line border w-[70%]"></div>
                    </div>
                  )}
                </NavLink>
                {!config?.disabled &&
                  config.sub?.map((subLink: any, i: number) =>
                    checkPermission(subLink?.permission) ? (
                      <NavLink
                        key={i}
                        to={subLink.path || ""}
                        // className="sider__sub__link"
                        // activeClassName="sider__active"
                        className={`group font-medium text-gray-400   hover:no-underline  flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-themeOrange hover:!text-white  ${
                          getRoute(config.path) !== currentPath
                            ? "text-sm font-medium  "
                            : "!text-white"
                        } 
                      ${collapsed ? "flex justify-center" : ""}
                      transition-all delay-[1ms]`}
                        activeClassName="bg-themeBlue font-medium  text-white visited:text-white text-white !no-underline"
                        onClick={event => handleLinkClick(event, subLink.path)}
                      >
                        <div
                          className={`${collapsed ? "" : "mr-4"} ${
                            config?.client && ""
                          }`}
                        >
                          <subLink.icon className="" />
                        </div>
                        <p>{!collapsed && subLink.title}</p>
                      </NavLink>
                    ) : null
                  )}
              </div>
            );
          })}
        </div>

        <div className="absolute bottom-10 items-center justify-center flex w-full">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </div>
      </Sider>
      <Layout>
        <Header className="bg-themeCore flex justify-end items-center shadow-sm">
          {/* <Bell className="header__notif__icon" /> */}
          <div className="notification relative mr-4">
            <div
              style={{
                backgroundColor: "#00519d",
              }}
              className="notificationDot rounded-full  p-1 w-1 h-1 absolute top-0 right-1 border-2"
            ></div>
            <div className="bell">
              <IoNotifications
                style={{
                  color: "#6b6d70",
                }}
                className="text-3xl "
              />
            </div>
          </div>
          <div className="app mr-4">
            <IoApps
              style={{
                color: "#6b6d70",
              }}
              className="text-3xl cursor-pointer"
              onClick={openModal}
            />

            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <div className="companiesList flex justify-around items-center flex-wrap gap-4">
                {data &&
                  data.map((company: any, index: any) => (
                    <div
                      className={`companyCard cursor-pointer p-5 hover:bg-[#dfecfe] transition-all delay-0  border border-white hover:border-gray-100 hover:shadow-lg rounded ${
                        getSelectedCompanyFromStorage() == company.id &&
                        "bg-[#dfecfe] shadow-lg"
                      }`}
                      onClick={() => handleCompanySelect(company)}
                    >
                      <div className="logo w-full">
                        <img
                          src={company?.large_logo_url}
                          className="w-40 h-40 object-contain scale-105"
                          alt={`${company?.name} logo`}
                        />
                      </div>
                      <div className="title text-center font-semibold">
                        {company?.name}
                      </div>
                    </div>
                  ))}
              </div>
            </Modal>
          </div>
          <div className="selectedCompnay mr-4 bg-white rounded-full">
            <img
              src={(data && getCompanyLogoUrl()) || "default-logo-url"} // Replace 'default-logo-url' with a placeholder or default image URL
              className="w-9 h-9 object-contain "
              alt="logo"
            />
          </div>
          <Dropdown
            overlay={menu}
            onVisibleChange={open => setIsMenuVisible(open)}
            visible={isMenuVisible}
            trigger={["click"]}
          >
            <div className="profile__menu__toggle">
              <Avatar size={32} src={profilePic} />
              <div className="flex flex-col mr-6 ml-3">
                <span className="leading-17.1 font-semibold text-sm">{`${user?.first_name} ${user?.last_name}`}</span>
                <span className="leading-14.6 font-medium text-xs">
                  {_.replace(_.startCase(_.toLower(user?.role)), "_", " ")}
                </span>
              </div>
              <div className="h-auto leading-none">
                <Down className="" />
              </div>
            </div>
          </Dropdown>
          <Modal isOpen={isModalApp} onClose={closeModalApp}>
            <div className="companiesList flex justify-around items-center flex-wrap gap-4"></div>
          </Modal>
        </Header>
        <Content
          style={{
            padding: "20px 16px",
            width: "100%",
            height: "calc(100vh - 64px)",
          }}
        >
          <div style={{ overflowY: "auto", maxHeight: "100%" }}>
            <div className=" mt-[0%]">{children}</div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
