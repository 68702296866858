import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export type JobSubFunction = {
  id: number;
  name: string;
};

interface Option {
  id: number | string;
  name: string;
  job_sub_functions?: JobSubFunction[]; // Array of job sub-functions
}

interface CustomFormSelectProps {
  title?: string;
  placeholder?: string;
  onChange: (option: Option) => void;
  options: Option[];
  disabledProp?: boolean;
  value: string | number | undefined;
  disabled: boolean;
}

const CustomFormSelect: React.FC<CustomFormSelectProps> = ({
  title = "",
  placeholder = "",
  onChange,
  options,
  disabledProp = false,
  value,
  disabled,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedOption = options.find(
      option => option.name === event.target.value
    );

    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  let selectedValue = options.find(option => option.id === value);
  return (
    <div className="CustomFormSelect w-full lg:w-[48%]">
      <h3 className="font-semibold text-themeBlue text-2xl leading-10">
        {title}
      </h3>

      <FormControl fullWidth className="!border-none !outline-none !my-5">
        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select
          disabled={disabled}
          sx={{ ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
          className="bg-white shadow-lg !rounded-none !border-none !outline-none5"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue?.name}
          label={placeholder}
          onChange={handleChange}
        >
          {[...options]
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort options alphabetically by name
            .map(option => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomFormSelect;
