import { ApiMethodes, endpoints, JobEvaluationDropDownKeys } from "@/constants";
import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

const description = null;

export enum EvaluationModels {
  FINANCIAL = "financial",
  NON_FINANCIAL = "non_financial",
}

export interface ICreateJobForEvaluation {
  job_title: string;
  department_name: string;
  business_unit?: number;
  region?: number;
  country?: number;
  evaluation_model: string | null | undefined;
  no_direct_report: string;
  job_function: number | null | undefined;
  sub_job_function: number | null | undefined;
}

export interface IUpdateJobEvaluation {
  job_title?: string;
  department_name?: string;
  evaluation_model?: string;
  no_direct_report?: string;
  knowledge_skills?: string;
  knowledge_skills_application?: string;
  minimum_level_of_education_required?: string;
  why_is_this_level_of_education_required?: string;
  area_of_specializations_or_degree?: string;
  problem_solving_solutioning?: string;
  nature_of_communication?: string;
  complexity?: string;
  role_impact_on_organization?: string;
  impact_of_decision_making?: string;
  non_financial_responsibility?: string;
  revenue_responsibility?: string;
  business_unit: number;
  country: number;
  region: number;
}

export const jobEvaluationApi = createApi({
  reducerPath: "jobEvaluationApi ",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  tagTypes: [
    "KnowledgeAndSkills",
    "KnowledgeSkillsAndApplication",
    "ProblemSolvingAndSolutioning",
    "NatureOfCommunication",
    "Complexity",
    "RoleImpactOnOrganisation",
    "ImpactOfDecisionMaking",
    "NonFinancialResponsibility",
    "RevenueResponsibility",
    "JobForEvaluation",
    "JobEvaluationCreate",
    "JobEvaluationUpdate",
    "JobEvaluationById",
    "JobEvaluations",
  ],
  endpoints: builder => ({
    /**
     * @Services - Job Evaluation Drop Downs
     */
    fetchAllKnowledgeAndSkills: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.KNOWLEDGE_AND_SKILLS
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["KnowledgeAndSkills"],
    }),
    fetchAllApplications: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.KNOWLEDGE_SKILLS_APPLICATION
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["KnowledgeSkillsAndApplication"],
    }),
    fetchAllProblemSolvingAndSolutioning: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.PROBLEM_SOLVING_SOLUTIONING
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["ProblemSolvingAndSolutioning"],
    }),
    fetchAllNatureOfCommunication: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.NATURE_OF_COMMUNICATION
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["NatureOfCommunication"],
    }),
    fetchAllComplexities: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.COMPLEXITY
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["Complexity"],
    }),
    fetchAllRoleImpactOnOrganisation: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.ROLE_IMPACT_ON_ORGANIZATION
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["RoleImpactOnOrganisation"],
    }),
    fetchAllImpactOfDecisionMaking: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.IMPACT_OF_DECISION_MAKING
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["ImpactOfDecisionMaking"],
    }),
    fetchAllNonFinancialResponsibility: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.NON_FINANCIAL_RESPONSIBILITY
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["NonFinancialResponsibility"],
    }),
    fetchAllRevenueResponsibility: builder.query({
      query: () => ({
        url: endpoints.JOB_EVALUATION.JOB_EVALUATION_DROPDOWNS(
          JobEvaluationDropDownKeys.REVENUE_RESPONSIBILITY
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["RevenueResponsibility"],
    }),
    createJobForEvaluation: builder.mutation({
      query: (payload: {
        company_id: number;
        body: ICreateJobForEvaluation;
      }) => ({
        url: endpoints.JOB_EVALUATION.CREATE_JOB_FOR_EVALUATION(
          payload.company_id
        ),
        method: ApiMethodes.POST,
        body: payload.body,
      }),
      invalidatesTags: ["JobEvaluations"],
    }),
    updateJobEvaluation: builder.mutation({
      query: (payload: {
        company_id: number;
        job_evaluation_id: number;
        body: IUpdateJobEvaluation;
      }) => ({
        url: endpoints.JOB_EVALUATION.UPDATE_JOB_EVALUATION(
          payload.company_id,
          payload.job_evaluation_id
        ),
        method: ApiMethodes.PUT,
        body: payload.body,
      }),
      // invalidatesTags: ["JobEvaluations"],
    }),

    fetchAllJobEvaluations: builder.query({
      query: (payload: { company_id: number; page: number }) => ({
        url: endpoints.JOB_EVALUATION.GET_ALL_JOB_EVALUATIONS(
          payload.company_id,
          payload.page
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["JobEvaluations"],
    }),
    fetchJobEvaluationById: builder.query({
      query: (payload: {
        company_id: number | string;
        job_evaluation_id: number | string;
      }) => ({
        url: endpoints.JOB_EVALUATION.GET_JOB_EVALUATION_BY_ID(
          payload.company_id,
          payload.job_evaluation_id
        ),
        method: ApiMethodes.GET,
      }),
      providesTags: ["JobEvaluationById"],
    }),
    createJobEvaluation: builder.mutation({
      query: (payload: {
        company_id: number;
        job_evaluation_id: number;
        body: IUpdateJobEvaluation;
      }) => ({
        url: endpoints.JOB_EVALUATION.CREATE_JOB_EVALUATION(
          payload.company_id,
          payload.job_evaluation_id
        ),
        method: ApiMethodes.POST,
        body: payload.body,
      }),
      invalidatesTags: ["JobEvaluations"],
    }),
    uploadJobEvaluationForms: builder.mutation({
      query: (payload: {
        company_id: number | string;
        job_evaluation_id: number | string;
        body: any;
      }) => ({
        url: endpoints.JOB_EVALUATION.UPLOAD_JOB_EVALUATION_DOCUMENTS(
          payload.company_id,
          payload.job_evaluation_id
        ),
        method: ApiMethodes.POST,
        body: payload.body,
        // header: { "Content-Type": "multipart/form-data" },
        formData: true,
      }),
      invalidatesTags: ["JobEvaluationById"],
    }),
  }),
});

export const {
  useFetchAllKnowledgeAndSkillsQuery,
  useFetchAllApplicationsQuery,
  useFetchAllProblemSolvingAndSolutioningQuery,
  useFetchAllNatureOfCommunicationQuery,
  useFetchAllComplexitiesQuery,
  useFetchAllRoleImpactOnOrganisationQuery,
  useFetchAllImpactOfDecisionMakingQuery,
  useFetchAllNonFinancialResponsibilityQuery,
  useFetchAllRevenueResponsibilityQuery,

  useCreateJobForEvaluationMutation,
  useUpdateJobEvaluationMutation,
  useCreateJobEvaluationMutation,
  useFetchAllJobEvaluationsQuery,
  useFetchJobEvaluationByIdQuery,
  useUploadJobEvaluationFormsMutation,
} = jobEvaluationApi;
